import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password' | 'date';
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
};

export const InputField = (props: InputFieldProps) => {
  const { type = 'text', label, className, registration, error, helperText } = props;
  return (
    <FieldWrapper label={label} error={error} helperText={helperText}>
      <input
        type={type}
        className={clsx(
          'block w-full appearance-none',
          'rounded-3xl border border-gray-300',
          'px-3 py-2 placeholder-gray-400 shadow-sm',
          'focus:border-black focus:outline-none focus:ring-black',
          'sm:text-sm',
          className
        )}
        {...registration}
      />
    </FieldWrapper>
  );
};
