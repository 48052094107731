import { Form, InputField, SelectField, UploadField } from '@/components/Form';
import { US_STATES } from '@/config';
import { serviceCountries } from '@/features/countries';
import { CountrySelect } from '@/features/misc';
import { useAuth } from '@/lib/auth';

import { DefaultUpdateSchema, schema } from '../schema';

import { CanadaForm } from './CanadaForm';
import { ColombiaForm } from './ColombiaForm';
import { EuropeForm } from './EuropeForm';
import { MexicoForm } from './MexicoForm';
import { UsaForm } from './UsaForm';

const usStateOptions = US_STATES.map((state) => ({
  label: state.title,
  value: state.code,
}));

type ActionFormProps = {
  onSubmit: (values: DefaultUpdateSchema) => Promise<void>;
};

export const ActionForm = ({ onSubmit }: ActionFormProps) => {
  const { user } = useAuth();
  const userConfig = user?.data.config;
  const serviceCurrencyCodes = Object.values(serviceCountries).map(
    (serviceCountry) => serviceCountry.currencyCode
  );
  const isEmployee = userConfig?.offerType === 'employee';
  const isServiceCurrency = userConfig?.offerCurrencyCode
    ? serviceCurrencyCodes.includes(userConfig?.offerCurrencyCode)
    : false;

  const currencyCode = isServiceCurrency ? userConfig?.offerCurrencyCode : undefined;

  return (
    <Form<DefaultUpdateSchema, typeof schema>
      id="default-update"
      onSubmit={onSubmit}
      schema={schema}
      options={{
        shouldUnregister: true,
        defaultValues: { currencyCode },
      }}
    >
      {({ register, formState, watch }) => {
        const currencyCode = watch('currencyCode');
        const country = watch('country');

        return (
          <>
            <div className="flex-1">
              <UploadField
                label="Bank Statement"
                error={formState.errors['bankStatement']}
                registration={register('bankStatement')}
                accept=".jpg, .jpeg, .png, .pdf"
              />
            </div>

            <div className="flex w-full flex-col gap-4 md:flex-row">
              <div className="flex-1">
                <InputField
                  label="Street"
                  error={formState.errors['street']}
                  registration={register('street')}
                />
              </div>

              <div className="flex-1">
                <InputField
                  label="City"
                  error={formState.errors['city']}
                  registration={register('city')}
                />
              </div>
            </div>

            <div className="flex w-full flex-col gap-4 md:flex-row">
              <div className="flex-1">
                <CountrySelect>
                  <SelectField
                    label="Country"
                    error={formState.errors['country']}
                    registration={register('country')}
                    options={[{ label: '', value: '' }]}
                  />
                </CountrySelect>
              </div>

              <div className="flex-1">
                {country === 'US' ? (
                  <SelectField
                    label="State"
                    error={formState.errors['state']}
                    registration={register('state')}
                    options={usStateOptions}
                  />
                ) : (
                  <InputField
                    label="State"
                    error={formState.errors['state']}
                    registration={register('state')}
                  />
                )}
              </div>

              <div className="flex-1">
                <InputField
                  label="Postal code"
                  error={formState.errors['postalCode']}
                  registration={register('postalCode')}
                />
              </div>
            </div>

            <div className="flex w-full space-x-4">
              <div className="flex-1">
                {isEmployee ? (
                  <SelectField
                    label="Bank account currency"
                    error={formState.errors['currencyCode']}
                    registration={register('currencyCode')}
                    options={[{ label: currencyCode, value: currencyCode }]}
                    disabled
                  />
                ) : (
                  <SelectField
                    label="Bank account currency"
                    error={formState.errors['currencyCode']}
                    registration={register('currencyCode')}
                    options={[
                      { label: '', value: '' },
                      { label: 'EUR', value: 'EUR' },
                      { label: 'CAD', value: 'CAD' },
                      { label: 'COP', value: 'COP' },
                      { label: 'MXN', value: 'MXN' },
                      { label: 'USD', value: 'USD' },
                    ]}
                  />
                )}
              </div>
            </div>

            {currencyCode === 'EUR' && <EuropeForm />}

            {currencyCode === 'CAD' && <CanadaForm />}

            {currencyCode === 'COP' && <ColombiaForm />}

            {currencyCode === 'MXN' && <MexicoForm />}

            {currencyCode === 'USD' && <UsaForm />}
          </>
        );
      }}
    </Form>
  );
};
