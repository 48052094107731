import { useAuth } from '@/lib/auth';

import { Layout } from '../components/Layout';
import {
  CaPersonalInformationForm,
  CoPersonalInformationForm,
  DefaultPersonalInformationForm,
  GlobalPersonalInformationForm,
  MxPersonalInformationForm,
} from '../components/PersonalInformation';

const renderPersonalInformationForm = (offerType?: string, offerCountryCode?: string) => {
  if (offerType === 'contractor') return <DefaultPersonalInformationForm />;
  if (offerCountryCode === 'CA') return <CaPersonalInformationForm />;
  if (offerCountryCode === 'CO') return <CoPersonalInformationForm />;
  if (offerCountryCode === 'MX') return <MxPersonalInformationForm />;
  return <GlobalPersonalInformationForm />;
};

export const PersonalInformation = () => {
  const { user } = useAuth();

  return (
    <Layout>
      {renderPersonalInformationForm(
        user?.data.config.offerType,
        user?.data.config.offerCountryCode
      )}
    </Layout>
  );
};
