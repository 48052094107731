import dayjs from 'dayjs';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import {
  Form as FormComponent,
  FormDrawer,
  InputField,
  SelectField,
  TextAreaField,
} from '@/components/Form';
import { useAuth } from '@/lib/auth';
import { isAfterDate } from '@/utils/validate';

import { useTimeOff } from '../api/getTimeOff';
import { useRequestTimeOff } from '../api/requestTimeOff';

const schema = z
  .object({
    requestType: z.string().min(1, 'Required'),
    startDate: z.string().min(1, 'Required'),
    endDate: z.string().min(1, 'Required'),
    description: z
      .string()
      .trim()
      .min(1, 'Required')
      .max(5000, 'Description should be less than 5000 characters'),
  })
  .refine(
    ({ startDate, endDate }) => {
      const minimumDate = dayjs(startDate).subtract(1, 'day').toString();
      return endDate ? isAfterDate(endDate, minimumDate) : true;
    },
    {
      path: ['endDate'],
      message: 'End date should be after start date or the same day',
    }
  );
type RequestTimeOffSchema = z.infer<typeof schema>;

const Form = ({ onSubmit }: { onSubmit: (values: RequestTimeOffSchema) => Promise<void> }) => {
  return (
    <FormComponent<RequestTimeOffSchema, typeof schema>
      id="request-time-off"
      onSubmit={onSubmit}
      schema={schema}
    >
      {({ register, formState }) => (
        <>
          <SelectField
            label="Request Type"
            error={formState.errors['requestType']}
            registration={register('requestType')}
            options={[
              { label: '', value: '' },
              { label: 'Vacation', value: 'vacation' },
              { label: 'Sick', value: 'sick' },
              { label: 'Parental Leave', value: 'parental-leave' },
            ]}
          />

          <div className="flex w-full flex-col gap-4 sm:flex-row">
            <div className="flex-1">
              <InputField
                label="Start Date"
                type="date"
                error={formState.errors['startDate']}
                registration={register('startDate', { deps: ['endDate'] })}
              />
            </div>
            <div className="flex-1">
              <InputField
                label="End Date"
                type="date"
                error={formState.errors['endDate']}
                registration={register('endDate')}
              />
            </div>
          </div>

          <TextAreaField
            label="Description"
            error={formState.errors['description']}
            registration={register('description')}
          />
        </>
      )}
    </FormComponent>
  );
};

export const RequestTimeOff = () => {
  const { user } = useAuth();
  const timeOffQuery = useTimeOff();

  const createAdditionalCompensationsMutation = useRequestTimeOff();

  if (user?.data.status === 'terminated') return null;

  const handleOnSubmit = async (values: RequestTimeOffSchema) => {
    await createAdditionalCompensationsMutation.mutateAsync(values);
  };

  if (!timeOffQuery.data) return null;

  return (
    <FormDrawer
      isDone={createAdditionalCompensationsMutation.isSuccess}
      triggerButton={<Button className="w-full lg:w-auto">Request</Button>}
      title="Request time off"
      submitButton={
        <Button
          form="request-time-off"
          type="submit"
          isLoading={createAdditionalCompensationsMutation.isLoading}
          disabled={createAdditionalCompensationsMutation.isLoading}
        >
          Submit
        </Button>
      }
    >
      <Form onSubmit={handleOnSubmit} />
    </FormDrawer>
  );
};
