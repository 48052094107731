import * as z from 'zod';

import { ReactComponent as WiseLogo } from '@/assets/wise_fast_flag_black_RGB.svg';
import { Button } from '@/components/Elements';
import {
  BarCta,
  Form as FormComponent,
  FormDrawer,
  InputField,
  UploadField,
} from '@/components/Form';
import { hasFirstItem, isValidFile, isValidInt } from '@/utils/validate';

import { UpdateWiseAccountDTO, useUpdateWiseAccount } from '../api/updateWiseAccount';

const schema = z.object({
  account: z
    .string()
    .trim()
    .min(1, 'Required')
    .min(4, { message: 'The account number format is invalid' })
    .max(17, { message: 'The account number format is invalid' })
    .refine(isValidInt, { message: 'Account number should be numeric' }),
  routingNumber: z
    .string()
    .trim()
    .min(1, 'Required')
    .length(9, 'Routing number should be 9 digits')
    .refine(isValidInt, { message: 'Routing number should be numeric' }),
  bankStatement: z
    .instanceof(FileList)
    .refine((bankStatement) => hasFirstItem(bankStatement), 'Required')
    .refine(isValidFile, 'File should be less than 20mb'),
});

type UpdateWiseAccountSchema = z.infer<typeof schema>;

type FormProps = {
  onSubmit: (values: UpdateWiseAccountSchema) => Promise<void>;
};

const Form = ({ onSubmit }: FormProps) => {
  return (
    <FormComponent<UpdateWiseAccountSchema, typeof schema>
      id="wise-update"
      onSubmit={onSubmit}
      schema={schema}
    >
      {({ register, formState }) => (
        <>
          <UploadField
            label="Bank Statement"
            error={formState.errors['bankStatement']}
            registration={register('bankStatement')}
            accept=".jpg, .jpeg, .png, .pdf"
          />
          <InputField
            label="Account"
            error={formState.errors['account']}
            registration={register('account')}
          />
          <InputField
            label="Routing Number"
            error={formState.errors['routingNumber']}
            registration={register('routingNumber')}
          />
        </>
      )}
    </FormComponent>
  );
};

const updateWiseAccountDTO = (values: UpdateWiseAccountSchema): UpdateWiseAccountDTO => {
  return {
    bankDetails: {
      account: values.account,
      routingNumber: values.routingNumber,
    },
    bankStatement: values.bankStatement[0],
  };
};

export const WiseUpdate = () => {
  const updateWiseAccountMutation = useUpdateWiseAccount();

  const handleOnSubmit = async (values: UpdateWiseAccountSchema) => {
    await updateWiseAccountMutation.mutateAsync(updateWiseAccountDTO(values));
  };

  return (
    <FormDrawer
      isDone={updateWiseAccountMutation.isSuccess}
      triggerButton={
        <BarCta
          logo={
            <div>
              <WiseLogo width={24} height={24} />
            </div>
          }
          title="Wise"
          description="Only for wise account holders"
        />
      }
      title="Update Wise account"
      submitButton={
        <Button
          form="wise-update"
          type="submit"
          isLoading={updateWiseAccountMutation.isLoading}
          disabled={updateWiseAccountMutation.isLoading}
        >
          Submit
        </Button>
      }
    >
      <Form onSubmit={handleOnSubmit} />
    </FormDrawer>
  );
};
