import * as z from 'zod';

import { Form as FormComponent, InputField, SelectField, TextAreaField } from '@/components/Form';
import { CurrencySelect } from '@/features/misc';
import { isValidCurrency } from '@/utils/validate';

import { TypeSelect } from '../TypeSelect';

const schema = z
  .object({
    type: z.string().min(1, 'Required'),
    description: z
      .string()
      .trim()
      .min(1, 'Required')
      .max(5000, 'Description should be less than 5000 characters'),
    currencyCode: z.string().min(1, 'Required'),
    amount: z.string().trim().min(1, 'Required'),
  })
  .refine(({ amount, currencyCode }) => isValidCurrency(amount, currencyCode), {
    path: ['amount'],
    message: 'Must be a valid amount',
  });

export type CreateAdditionalCompensationSchema = z.infer<typeof schema>;

type ActionFormProps = {
  onSubmit: (values: CreateAdditionalCompensationSchema) => Promise<void>;
};

export const ActionForm = ({ onSubmit }: ActionFormProps) => {
  return (
    <FormComponent<CreateAdditionalCompensationSchema, typeof schema>
      id="create-additional-compensation"
      onSubmit={onSubmit}
      schema={schema}
    >
      {({ register, formState }) => (
        <>
          <InputField
            label="Amount"
            error={formState.errors['amount']}
            registration={register('amount')}
          />

          <CurrencySelect>
            <SelectField
              label="Currency"
              error={formState.errors['currencyCode']}
              registration={register('currencyCode')}
              options={[
                { label: '', value: '' },
                { label: 'USD', value: 'USD' },
              ]}
            />
          </CurrencySelect>

          <TypeSelect>
            <SelectField
              label="Type"
              options={[
                { label: '', value: '' },
                { label: 'reimbursement', value: 'reimbursement' },
                { label: 'other', value: 'other' },
              ]}
              error={formState.errors['type']}
              registration={register('type')}
            />
          </TypeSelect>

          <TextAreaField
            label="Description"
            error={formState.errors['description']}
            registration={register('description')}
          />
        </>
      )}
    </FormComponent>
  );
};
