import { useFormContext } from 'react-hook-form';

import { InputField } from '@/components/Form';

import { DefaultUpdateSchema } from '../schema';

export const MexicoForm = () => {
  const { formState, register } = useFormContext<DefaultUpdateSchema>();

  return (
    <>
      <InputField
        label="Financial institution"
        error={formState.errors['name']}
        helperText="ex., Chase, TD Bank, HSBC, Bancolombia, CITI"
        registration={register('name')}
      />

      <div className="flex w-full space-x-4">
        <div className="flex-1">
          <InputField
            label="Account number"
            error={formState.errors['account']}
            registration={register('account')}
          />
        </div>

        <div className="flex-1">
          <InputField
            label="CLABE"
            error={formState.errors['clabe']}
            registration={register('clabe')}
          />
        </div>
      </div>
    </>
  );
};
