import { Pagination, Spinner, Table } from '@/components/Elements/';
import { EmptyState } from '@/components/Elements/EmptyState';
import { SectionHeading } from '@/components/Layout/SectionHeading';
import { formatDate } from '@/utils/format';

import { usePaystubs } from '../api/getPaystubs';
import { Data } from '../types';

export const Paystubs = () => {
  const paystubsQuery = usePaystubs({});

  if (paystubsQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!paystubsQuery.data) return null;

  const { data, links, meta } = paystubsQuery.data;

  if (meta.total < 1)
    return (
      <div className="bg-ebony-clay-50 p-12 sm:border sm:border-ebony-clay-100 sm:bg-white sm:p-24">
        <EmptyState title="No paystubs found" />
      </div>
    );

  return (
    <div>
      <SectionHeading title="Paystubs" />
      <Table<Data>
        data={data}
        isFetching={paystubsQuery.isFetching && paystubsQuery.isPreviousData}
        columns={[
          {
            title: 'Date',
            field: 'createdAt',
            Cell: ({ entry: { createdAt } }) => <p>{formatDate(createdAt)}</p>,
          },
          {
            title: 'Description',
            field: 'name',
            isFullWidth: true,
            Cell: ({ entry: { name } }) => <p className="max-w-3xl truncate">{name}</p>,
          },
          {
            title: '',
            field: 'path',
            Cell: ({ entry: { path } }) =>
              path ? (
                <a
                  href={path}
                  className="font-medium text-indigo-500 hover:text-indigo-400 focus:underline focus:underline-offset-2 focus:outline-none"
                  target="_blank"
                  rel="noreferrer"
                >
                  Download
                </a>
              ) : (
                <></>
              ),
          },
        ]}
        pagination={
          <Pagination
            links={links}
            meta={meta}
            path="/payments"
            isFetching={paystubsQuery.isFetching && paystubsQuery.isPreviousData}
          />
        }
      />
    </div>
  );
};
