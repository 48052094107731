import { Button, NavLink } from '@/components/Elements';
import { APP_BASE_PATH } from '@/config';
import { TPaginatedQuery } from '@/types';
import { urlSearch } from '@/utils/url';

export type PaginationProps = {
  links: TPaginatedQuery<unknown>['links'];
  meta: TPaginatedQuery<unknown>['meta'];
  path: string;
  isFetching: boolean;
};

type NavLinkButtonProps = {
  disabled: boolean;
  label: string;
  to: string;
  isFetching: boolean;
};

const NavLinkButton = ({ disabled, label, to, isFetching }: NavLinkButtonProps) => {
  const buttonTabIndex = disabled ? -1 : undefined;

  return (
    <NavLink
      onClick={(event) => {
        if (disabled) event.preventDefault();
      }}
      to={to}
      tabIndex={-1}
    >
      <Button
        disabled={disabled || isFetching}
        variant="alternative"
        tabIndex={buttonTabIndex}
        isLoading={isFetching}
      >
        {label}
      </Button>
    </NavLink>
  );
};

export const Pagination = ({ links, meta, path, isFetching }: PaginationProps) => {
  const prevUrl = `${APP_BASE_PATH}${path}${urlSearch(links.prev)}`;
  const nextUrl = `${APP_BASE_PATH}${path}${urlSearch(links.next)}`;

  if (meta.total === 0) return null;

  return (
    <nav className="flex items-center sm:pt-3">
      <div className="flex items-center justify-between gap-x-4">
        <div className="hidden sm:block">
          <p className="text-sm leading-5 text-gray-700">
            Showing
            {meta.total > 0 && (
              <span>
                <span className="font-medium"> {meta.from}</span> to
                <span className="font-medium"> {meta.to}</span> of
              </span>
            )}
            <span className="font-medium"> {meta.total}</span>{' '}
            {meta.total > 0 ? 'results' : 'result'}
          </p>
        </div>

        <div className="flex flex-1 justify-between gap-x-2 sm:justify-end">
          <NavLinkButton
            disabled={!links.prev}
            label="Previous"
            isFetching={isFetching}
            to={prevUrl}
          />
          <NavLinkButton disabled={!links.next} label="Next" isFetching={isFetching} to={nextUrl} />
        </div>
      </div>
    </nav>
  );
};
