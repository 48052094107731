import { Well } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { PageHeading } from '@/components/Layout/PageHeading';
import { SectionHeading } from '@/components/Layout/SectionHeading';
import { SendResetFormStatic } from '@/features/auth';

const title = 'Settings';

export const Settings = () => {
  return (
    <ContentLayout title={title}>
      <Well>
        <>
          <PageHeading title={title} />
          <div>
            <SectionHeading title="Security Zone" />
            <div className="divide-y divide-ebony-clay-200 sm:space-y-5">
              <SendResetFormStatic />
            </div>
          </div>
        </>
      </Well>
    </ContentLayout>
  );
};
