import { useQuery } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Data } from '@/types';

import { AdditionalCompensationsTypes } from '../types';

export const getTypes = (): Promise<Data<AdditionalCompensationsTypes>> =>
  axios.get(`${API_BASE_PATH}/payroll/additional-compensations/types`);

type QueryFnType = typeof getTypes;

type UseTypesOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useTypes = ({ config }: UseTypesOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['types'],
    queryFn: () => getTypes(),
  });
};
