import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { InputField, SelectField } from '@/components/Form';

import { DefaultUpdateSchema } from '../schema';

export const CanadaForm = () => {
  const { formState, register, watch, resetField } = useFormContext<DefaultUpdateSchema>();

  const currencyCode = watch('currencyCode');

  useEffect(() => {
    resetField('type', { defaultValue: '' });
  }, [currencyCode, resetField]);

  return (
    <>
      <div className="flex w-full space-x-4">
        <div className="flex-1">
          <InputField
            label="Institution Number"
            error={formState.errors['institutionNumber']}
            registration={register('institutionNumber')}
          />
        </div>
        <div className="flex-1">
          <SelectField
            label="Account type"
            error={formState.errors['type']}
            registration={register('type')}
            options={[
              { label: '', value: '' },
              { label: 'Checking', value: 'checking' },
              { label: 'Savings', value: 'saving' },
            ]}
          />
        </div>
      </div>

      <div className="flex w-full space-x-4">
        <div className="flex-1">
          <InputField
            label="Account number"
            error={formState.errors['account']}
            registration={register('account')}
          />
        </div>
        <div className="flex-1">
          <InputField
            label="Transit number"
            error={formState.errors['transitCode']}
            registration={register('transitCode')}
          />
        </div>
      </div>
    </>
  );
};
