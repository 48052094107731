import clsx from 'clsx';
import * as z from 'zod';

import { Form as FormComponent, UploadField } from '@/components/Form';
import { MAX_IMAGE_SIZE_BYTES } from '@/config';
import { Data, FileMetadata } from '@/types';

import { UploadFileDTO, useUploadFile } from '../../api/uploadFile';

const isValidFile = (fileList: FileList) => {
  if (fileList.length === 0) {
    return true;
  } else if (fileList[0].size < MAX_IMAGE_SIZE_BYTES) {
    return true;
  } else {
    return false;
  }
};

const schema = z.object({
  file: z.instanceof(FileList).refine(isValidFile, 'File should be less than 20mb'),
});

export type UploadSingleFileSchema = z.infer<typeof schema>;

type UploadSingleFileFormProps = {
  disabled: boolean;
  formId: string;
  onSuccess: (data: Data<FileMetadata>) => void;
  onMutate: (variables: UploadFileDTO) => void;
};

export const UploadSingleFileForm = ({
  disabled,
  formId,
  onSuccess,
  onMutate,
}: UploadSingleFileFormProps) => {
  const uploadFileMutation = useUploadFile({
    config: {
      onMutate: onMutate,
    },
  });

  const isDisabled = disabled || uploadFileMutation.isLoading;

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;

    if (fileList && fileList.length > 0) {
      await uploadFileMutation.mutateAsync(
        {
          file: fileList[0],
        },
        {
          onSuccess: onSuccess,
        }
      );
    }
  };

  return (
    <>
      <FormComponent<UploadSingleFileSchema, typeof schema> id={formId} schema={schema}>
        {({ register, formState, reset }) => (
          <>
            <div>
              <UploadField
                accept=".jpg, .jpeg, .png, .pdf, .zip"
                error={formState.errors['file']}
                registration={register('file', {
                  disabled: isDisabled,
                  onChange: async (event) => {
                    await handleFileChange(event);
                    reset();
                  },
                })}
              />
              <div className="mt-1 text-xs italic text-gray-500">
                Only JPG, JPEG, PNG, PDF and ZIP files are allowed.
              </div>
            </div>
            <label
              className={clsx(
                'mt-4 block cursor-pointer text-sm font-medium text-dodger-blue-500 hover:text-dodger-blue-600',
                isDisabled && 'cursor-not-allowed'
              )}
              htmlFor="file"
            >
              Add another file
            </label>
          </>
        )}
      </FormComponent>
    </>
  );
};
