import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { Data, DefaultMutationResponse } from '@/types';

import { BankAccount } from '../types';

export type UpdateWiseAccountDTO = {
  bankDetails: {
    account: string;
    routingNumber: string;
  };
  bankStatement: File;
};

export const updateWiseAccount = (data: UpdateWiseAccountDTO): Promise<DefaultMutationResponse> => {
  const formData = new FormData();

  Object.entries(data.bankDetails).map(([key, value]) => {
    if (value) formData.append(`bankDetails[${key}]`, value);
  });

  formData.append('bankStatement', data.bankStatement);

  return axios.post(`${API_BASE_PATH}/bank-account/wise`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

type UseUpdateWiseAccountOptions = {
  config?: MutationConfig<typeof updateWiseAccount>;
};

export const useUpdateWiseAccount = ({ config }: UseUpdateWiseAccountOptions = {}) => {
  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('bank-account');

      const previousBankAccount = queryClient.getQueryData<BankAccount>('bank-account');

      queryClient.setQueryData(
        'bank-account',
        (old: Data<BankAccount> | undefined) =>
          ({
            data: {
              ...old?.data,
              status: 'pending',
            },
          } as Data<BankAccount>)
      );

      return { previousBankAccount };
    },
    onError: (_, __, context: any) => {
      if (context?.previousBankAccount) {
        queryClient.setQueryData('bank-account', context.previousBankAccount);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('bank-account');
    },
    ...config,
    mutationFn: updateWiseAccount,
  });
};
