import { useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { Button, Spinner } from '@/components/Elements';
import { CheckBoxField, Form, InputField, SelectField } from '@/components/Form';
import { BetaCountrySelect } from '@/features/misc';
import { useAuth } from '@/lib/auth';
import { mixpanelTrackSubmit } from '@/lib/mixpanel';

import { CreateDTO, useCreate } from '../../api/create';
import { useInformation } from '../../api/getInformation';
import { State } from '../State';

const personalInformationSchema = z.object({
  firstName: z.string().trim().min(1, 'Required'),
  middleName: z.string().trim().optional(),
  lastName: z.string().trim().min(1, 'Required'),
  phoneNumber: z.string().trim().min(1, 'Required'),
  birthdate: z.string().trim().min(1, 'Required'),
  nationality: z.string().trim().min(1, 'Required'),
});

const personalIdsSchema = z.object({
  eps: z.string().trim().min(1, 'Required'),
  pensionFund: z.string().trim().min(1, 'Required'),
  severanceFund: z.string().trim().min(1, 'Required'),
  identityId: z.string().trim().min(1, 'Required'),
  identityIdType: z.string().trim().min(1, 'Required'),
  voluntaryContributions: z.array(z.string()).optional(),
  workPermitId: z.string().trim().min(1, 'Required').optional(),
});

const addressSchema = z.object({
  street: z.string().trim().min(1, 'Required'),
  city: z.string().trim().min(1, 'Required'),
  state: z.string().trim().min(1, 'Required'),
  postalCode: z.string().trim().min(1, 'Required'),
  countryCode: z.string().trim().min(1, 'Required'),
});

const coPersonalInformationSchema = z.object({
  ...personalInformationSchema.shape,
  ...personalIdsSchema.shape,
  ...addressSchema.shape,
});

export type TCoPersonalInformationSchema = z.infer<typeof coPersonalInformationSchema>;

const createDTO = (values: TCoPersonalInformationSchema): CreateDTO => {
  return {
    payee: {
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      birthdate: values.birthdate,
      nationality: values.nationality,
    },
    address: {
      street: values.street,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      countryCode: values.countryCode,
    },
    personalIds: {
      eps: values.eps,
      pensionFund: values.pensionFund,
      severanceFund: values.severanceFund,
      identityId: values.identityId,
      identityIdType: values.identityIdType,
      voluntaryContributions: values.voluntaryContributions,
      workPermitId: values.workPermitId,
    },
  };
};

export const CoPersonalInformationForm = () => {
  const navigate = useNavigate();

  const onboardingInformationQuery = useInformation();
  const createMutation = useCreate();
  const { user } = useAuth();

  const handleOnSubmit = async (values: TCoPersonalInformationSchema) => {
    await createMutation.mutateAsync(createDTO(values), {
      onSettled: (data) => {
        if (data) navigate('/onboarding/banking');
      },
    });
    mixpanelTrackSubmit('submit_personal_information', {
      $worker_type: user?.data.config.offerType,
      $company: user?.data.config.offerCompany,
      $start_date: user?.data.config.offerStartAt,
    });
  };

  if (onboardingInformationQuery.isLoading)
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner size="lg" />
      </div>
    );

  const offerCountryCode = user?.data.config.offerCountryCode;
  const onboardingInformation = onboardingInformationQuery.data?.data;

  const defaultValues: Partial<TCoPersonalInformationSchema> = {
    ...onboardingInformation?.address,
    ...onboardingInformation?.payee,
    ...onboardingInformation?.personalIds,
  };

  return (
    <Form<TCoPersonalInformationSchema, typeof coPersonalInformationSchema>
      onSubmit={async (payee) => {
        handleOnSubmit(payee);
      }}
      schema={coPersonalInformationSchema}
      className="space-y-8 divide-y divide-gray-200"
      options={{
        defaultValues,
        shouldUnregister: true,
      }}
    >
      {({ formState, register, resetField, watch }) => {
        const nationality = watch('nationality');
        const isNational = nationality === offerCountryCode;

        return (
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div>
                <legend className="sr-only">Personal Information</legend>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Personal Information
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Please fill in your personal details as they appear on your government issued ID.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-2">
                  <InputField
                    label="First name"
                    error={formState.errors['firstName']}
                    registration={register('firstName')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="Middle name (optional)"
                    error={formState.errors['middleName']}
                    registration={register('middleName')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="Last name"
                    error={formState.errors['lastName']}
                    registration={register('lastName')}
                    helperText="Exactly as it appears on your government issued ID"
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="Phone number"
                    error={formState.errors['phoneNumber']}
                    registration={register('phoneNumber')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="Birth date"
                    type="date"
                    error={formState.errors['birthdate']}
                    registration={register('birthdate')}
                  />
                </div>
                <div className="sm:col-span-4">
                  <BetaCountrySelect
                    label="Nationality"
                    error={formState.errors['nationality']}
                    registration={register('nationality')}
                  />
                </div>
              </div>
            </div>
            <div className="pt-8">
              <div>
                <legend className="sr-only">Please fill in your personal ID information.</legend>
                <p className="mt-1 text-sm text-gray-500">
                  Please fill in your personal ID information.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-2">
                  <InputField
                    label="EPS"
                    error={formState.errors['eps']}
                    registration={register('eps')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="Pension fund"
                    error={formState.errors['pensionFund']}
                    registration={register('pensionFund')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="Severance fund"
                    error={formState.errors['severanceFund']}
                    registration={register('severanceFund')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <SelectField
                    label="Document ID type"
                    error={formState.errors['identityIdType']}
                    registration={register('identityIdType')}
                    options={[
                      { label: '', value: '' },
                      { label: 'Cédula de Ciudadanía', value: 'cedula-ciudadania' },
                      { label: 'Cédula de Extranjería', value: 'cedula-extranjeria' },
                      { label: 'Tarjeta de Identidad', value: 'tarjeta-identidad' },
                      { label: 'Passport', value: 'passport' },
                    ]}
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="Document ID number"
                    error={formState.errors['identityId']}
                    registration={register('identityId')}
                  />
                </div>
                {!isNational && (
                  <div className="sm:col-span-2">
                    <InputField
                      label="Work permit number"
                      error={formState.errors['workPermitId']}
                      registration={register('workPermitId')}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="pt-8">
              <fieldset>
                <legend className="sr-only">
                  Please select any voluntary contributions (optional)
                </legend>
                <p className="mt-1 text-sm text-gray-500">
                  Please select any voluntary contributions (optional)
                </p>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-8">
                  <div className="sm:col-span-4">
                    <CheckBoxField
                      label="FPV contributions"
                      value="FPV"
                      registration={register('voluntaryContributions')}
                    />
                  </div>
                  <div className="sm:col-span-4">
                    <CheckBoxField
                      label="AFC contributions"
                      value="AFC"
                      registration={register('voluntaryContributions')}
                    />
                  </div>
                  <div className="sm:col-span-4">
                    <CheckBoxField
                      label="Housing interests contributions"
                      value="HIC"
                      registration={register('voluntaryContributions')}
                    />
                  </div>
                  <div className="sm:col-span-4">
                    <CheckBoxField
                      label="Economic dependents contributions"
                      value="EDC"
                      registration={register('voluntaryContributions')}
                    />
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="pt-8">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">Address</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Please enter the address you will be working from.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <InputField
                    label="Street"
                    error={formState.errors['street']}
                    registration={register('street')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="City"
                    error={formState.errors['city']}
                    registration={register('city')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <State countryCode={watch('countryCode')} />
                </div>
                <div className="sm:col-span-1">
                  <InputField
                    label="Postal code"
                    error={formState.errors['postalCode']}
                    registration={register('postalCode')}
                  />
                </div>
                <div className="sm:col-span-3">
                  <BetaCountrySelect
                    label="Country"
                    error={formState.errors['countryCode']}
                    registration={register('countryCode', {
                      onChange: () => {
                        resetField('state', { defaultValue: '' });
                      },
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-end">
                <Button
                  isLoading={createMutation.isLoading}
                  disabled={createMutation.isLoading}
                  type="submit"
                  size="lg"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        );
      }}
    </Form>
  );
};
