import { useQuery } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Data } from '@/types';

import { EmploymentDocument } from '../types';

export const getDocuments = (): Promise<Data<EmploymentDocument[]>> =>
  axios.get(`${API_BASE_PATH}/documents`);

type QueryFnType = typeof getDocuments;

type UseDocumentsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useDocuments = ({ config }: UseDocumentsOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['documents'],
    queryFn: () => getDocuments(),
  });
};
