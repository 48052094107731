import { Link } from '@/components/Elements';
import { useAuth } from '@/lib/auth';

import {
  CaDocumentationForm,
  CoDocumentationForm,
  DefaultDocumentationForm,
  GlobalDocumentationForm,
  MxDocumentationForm,
} from '../components/Documentation';
import { Layout } from '../components/Layout';

const renderDocumentationForm = (offerType?: string, offerCountryCode?: string) => {
  if (offerType === 'contractor') return <DefaultDocumentationForm />;
  if (offerCountryCode === 'CA') return <CaDocumentationForm />;
  if (offerCountryCode === 'CO') return <CoDocumentationForm />;
  if (offerCountryCode === 'MX') return <MxDocumentationForm />;
  return <GlobalDocumentationForm />;
};

export const Documentation = () => {
  const { user } = useAuth();

  return (
    <Layout
      backButton={
        <Link className="text-lg" to="/onboarding/banking">
          &larr; {'Back'}
        </Link>
      }
    >
      {renderDocumentationForm(user?.data.config.offerType, user?.data.config.offerCountryCode)}
    </Layout>
  );
};
