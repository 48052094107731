import { Well } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { PageHeading } from '@/components/Layout/PageHeading';
import { useAuth } from '@/lib/auth';

import { RequestTimeOff } from '../components/RequestTimeOff';
import { TimeOffStats } from '../components/TimeOffStats';
import { TimeOffTable } from '../components/TimeOffTable';

const title = 'Time Off';

export const TimeOff = () => {
  const { user } = useAuth();
  const isEmployee = user?.data.config.offerType === 'employee';

  return (
    <ContentLayout title={title}>
      <Well>
        <>
          <PageHeading title={title} />
          {isEmployee && <TimeOffStats />}
          <TimeOffTable addEntryButton={<RequestTimeOff key="TimeOffRequestTimeOff" />} />
        </>
      </Well>
    </ContentLayout>
  );
};
