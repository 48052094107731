import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { Data, DefaultMutationResponse } from '@/types';

import { BankAccount } from '../types';

export type UpdateBankAccountDTO = {
  bankDetails: {
    account?: string;
    clabe?: string;
    countryCode: string;
    currencyCode: string;
    institutionNumber?: string;
    name?: string;
    routingNumber?: string;
    swiftBic?: string;
    transitCode?: string;
    type?: string;
  };
  bankAddress: {
    street: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  bankStatement: File;
};

export const updateBankAccount = (data: UpdateBankAccountDTO): Promise<DefaultMutationResponse> => {
  const formData = new FormData();

  Object.entries(data.bankAddress).map(([key, value]) => {
    if (value) formData.append(`bankAddress[${key}]`, value);
  });

  Object.entries(data.bankDetails).map(([key, value]) => {
    if (value) formData.append(`bankDetails[${key}]`, value);
  });

  formData.append('bankStatement', data.bankStatement);

  return axios.post(`${API_BASE_PATH}/bank-account`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

type UseUpdateBankAccountOptions = {
  config?: MutationConfig<typeof updateBankAccount>;
};

export const useUpdateBankAccount = ({ config }: UseUpdateBankAccountOptions = {}) => {
  return useMutation({
    onMutate: async () => {
      const queryKey = 'bank-account';
      await queryClient.cancelQueries(queryKey);

      const previousBankAccount = queryClient.getQueryData<BankAccount>('bank-account');

      queryClient.setQueryData(
        'bank-account',
        (old: Data<BankAccount> | undefined) =>
          ({
            data: {
              ...old?.data,
              status: 'pending',
            },
          } as Data<BankAccount>)
      );

      return { previousBankAccount };
    },
    onError: (_, __, context: any) => {
      if (context?.previousBankAccount) {
        queryClient.setQueryData('bank-account', context.previousBankAccount);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('bank-account');
    },
    ...config,
    mutationFn: updateBankAccount,
  });
};
