import { useQuery } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Data } from '@/types';

import { Offer } from '../types';

export const getOffer = (): Promise<Data<Offer>> => axios.get(`${API_BASE_PATH}/offer`);

type QueryFnType = typeof getOffer;

type UseOfferOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useOffer = ({ config }: UseOfferOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['offer'],
    queryFn: () => getOffer(),
  });
};
