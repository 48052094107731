import {
  CreditCardIcon,
  CurrencyDollarIcon,
  FolderOpenIcon,
  HeartIcon,
  HomeIcon,
  LibraryIcon,
  SunIcon,
  UserIcon,
  ViewGridAddIcon,
} from '@heroicons/react/outline';
import clsx from 'clsx';

import { NavLink } from '@/components/Elements/NavLink';
import { APP_BASE_PATH } from '@/config';

export type SideNavigationItem = {
  name: string;
  to: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

const navigation: SideNavigationItem[] = [
  { name: 'Home', to: APP_BASE_PATH, icon: HomeIcon },
  { name: 'Personal Details', to: `${APP_BASE_PATH}/personal-details`, icon: UserIcon },
  { name: 'Job & Pay', to: `${APP_BASE_PATH}/job-pay`, icon: CurrencyDollarIcon },
  {
    name: 'Additional Compensation',
    to: `${APP_BASE_PATH}/additional-compensation`,
    icon: ViewGridAddIcon,
  },
  { name: 'Payments', to: `${APP_BASE_PATH}/payments`, icon: CreditCardIcon },
  { name: 'Time Off', to: `${APP_BASE_PATH}/time-off`, icon: SunIcon },
  { name: 'Documents', to: `${APP_BASE_PATH}/documents`, icon: FolderOpenIcon },
  { name: 'Benefits', to: `${APP_BASE_PATH}/benefits`, icon: HeartIcon },
  { name: 'Bank Account', to: `${APP_BASE_PATH}/bank-account`, icon: LibraryIcon },
];

export const DesktopSideNavigation = () => (
  <nav className="flex-1 space-y-1 px-2 pb-4">
    {navigation.map((item, index) => (
      <NavLink
        end={index === 0}
        key={item.name}
        to={item.to}
        className={({ isActive }) => desktopNavLinkClass(isActive)}
      >
        {({ isActive }) => (
          <>
            <item.icon className={desktopItemIconClass(isActive)} aria-hidden="true" />
            {item.name}
          </>
        )}
      </NavLink>
    ))}
  </nav>
);

export const MobileSideNavigation = ({
  onItemClick,
}: {
  onItemClick: React.MouseEventHandler<HTMLAnchorElement>;
}) => (
  <nav className="my-1 space-y-1 px-2">
    {navigation.map((item, index) => (
      <NavLink
        end={index === 0}
        key={item.name}
        to={item.to}
        onClick={onItemClick}
        className={({ isActive }) => mobileNavLinkClass(isActive)}
      >
        {({ isActive }) => (
          <>
            <item.icon className={mobileItemIconClass(isActive)} aria-hidden="true" />
            {item.name}
          </>
        )}
      </NavLink>
    ))}
  </nav>
);

const desktopNavLinkClass = (isActive: boolean): string =>
  clsx(
    isActive
      ? 'bg-ebony-clay-100 text-ebony-clay-900'
      : 'text-ebony-clay-600 hover:bg-ebony-clay-50 hover:text-ebony-clay-900',
    'group flex items-center rounded-full px-4 py-2 text-xs font-medium focus:outline-black'
  );

const desktopItemIconClass = (isActive: boolean): string =>
  clsx(
    isActive ? 'text-ebony-clay-600' : 'text-ebony-clay-500 group-hover:text-ebony-clay-600',
    'mr-3 h-6 w-6 flex-shrink-0'
  );

const mobileNavLinkClass = (isActive: boolean): string =>
  clsx(
    isActive
      ? 'bg-ebony-clay-100 text-ebony-clay-900'
      : 'text-ebony-clay-600 hover:bg-ebony-clay-50 hover:text-ebony-clay-900',
    'group flex items-center rounded-full px-2 py-2 text-base font-medium focus:outline-black'
  );

const mobileItemIconClass = (isActive: boolean): string =>
  clsx(
    isActive ? 'text-ebony-clay-600' : 'text-ebony-clay-500 group-hover:text-ebony-clay-600',
    'mr-4 h-6 w-6 flex-shrink-0'
  );
