import { cloneElement } from 'react';

import { SelectFieldProps } from '@/components/Form';
import { useCountries } from '@/features/countries';

export const CountrySelect = ({
  children,
  omitCountries,
}: {
  children: React.ReactElement;
  omitCountries?: string[];
}) => {
  const countriesQuery = useCountries({
    config: {
      staleTime: Infinity,
    },
  });

  if (countriesQuery.isLoading) return cloneElement<SelectFieldProps>(children, { disabled: true });

  if (!countriesQuery.data) return children;

  const { data: countries } = countriesQuery.data;

  const filteredCountries = countries.filter((country) => !omitCountries?.includes(country.code));
  const sortedCountries = filteredCountries.map((country) => country).sort();

  const countryOptions: SelectFieldProps['options'] = sortedCountries.map((country) => ({
    label: country.name,
    value: country.code,
  }));

  return cloneElement<SelectFieldProps>(children, {
    options: [{ label: '', value: '' }, ...countryOptions],
  });
};
