import { ExclamationIcon } from '@heroicons/react/outline';

import { Button } from '@/components/Elements';
import { ModalDialog } from '@/components/Elements/ModalDialog';

import { useCancelTimeOff } from '../api/cancelTimeOff';

type CancelTimeOffProps = {
  id: number;
};

const triggerButton = (
  <button className="rounded-full px-2 text-froly-600 hover:text-froly-500 focus:underline focus:underline-offset-2 focus:outline-none">
    Cancel
  </button>
);

export const CancelTimeOff = ({ id }: CancelTimeOffProps) => {
  const cancelTimeOffMutation = useCancelTimeOff();

  const handleConfirmationClick = async () => {
    await cancelTimeOffMutation.mutateAsync({ id });
  };

  return (
    <ModalDialog
      icon={<ExclamationIcon className="h-6 w-6 text-froly-600" />}
      description="This action can&rsquo;t be undone, if you change your mind you will have to create another time off request."
      hasCancelButton={false}
      showDismiss={true}
      confirmButton={
        <Button
          isLoading={cancelTimeOffMutation.isLoading}
          type="button"
          onClick={handleConfirmationClick}
          variant="danger"
          disabled={cancelTimeOffMutation.isLoading}
        >
          Cancel time off
        </Button>
      }
      triggerButton={triggerButton}
      title="Are you sure you want to cancel this time off request?"
      isDone={cancelTimeOffMutation.isSuccess}
    />
  );
};
