import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { Data, FileMetadata } from '@/types';

import { AdditionalCompensations } from '../types';

export type CreateAdditionalCompensationsDTO = {
  amount: string;
  type: string;
  description: string;
  currencyCode: string;
  files: FileMetadata[];
};

export const createAdditionalCompensations = ({
  ...data
}: CreateAdditionalCompensationsDTO): Promise<Data<AdditionalCompensations>> => {
  return axios.post(`${API_BASE_PATH}/payroll/additional-compensations`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

type UseCreateAdditionalCompensationsOptions = {
  config?: MutationConfig<typeof createAdditionalCompensations>;
};

export const useCreateAdditionalCompensations = ({
  config,
}: UseCreateAdditionalCompensationsOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (newReimbursement) => {
      await queryClient.cancelQueries('additional-compensations');

      const previousAdditionalCompensations = queryClient.getQueryData<AdditionalCompensations[]>(
        'additional-compensations'
      );

      queryClient.setQueryData('additional-compensations', [
        ...(previousAdditionalCompensations || []),
        newReimbursement,
      ]);

      return { previousAdditionalCompensations };
    },
    onError: (_, __, context: any) => {
      if (context?.previousAdditionalCompensations) {
        queryClient.setQueryData(
          'additional-compensations',
          context.previousAdditionalCompensations
        );
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries('additional-compensations');
      if (data) {
        addNotification({
          type: 'success',
          title: 'Request sent!',
        });
      }
    },
    ...config,
    mutationFn: createAdditionalCompensations,
  });
};
