import { SectionHeading } from '@/components/Layout/SectionHeading';

import { DefaultUpdate } from './DefaultUpdate';
import { WiseUpdate } from './WiseUpdate';

export const UpdateBankAccount = ({ workerType }: { workerType: string }) => {
  const isContractor = workerType === 'contractor';

  return (
    <div>
      <SectionHeading title="Update bank account" />
      <div className="divide-y divide-ebony-clay-200 sm:space-y-5 sm:divide-none">
        <div>
          <DefaultUpdate />
        </div>
        {isContractor && (
          <div>
            <WiseUpdate />
          </div>
        )}
      </div>
    </div>
  );
};
