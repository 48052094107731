import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { APP_BASE_PATH } from '@/config';
import { Banking } from '@/features/banking';
import { Benefits } from '@/features/benefits';
import { Documents } from '@/features/documents';
import { Dashboard } from '@/features/misc';
import { JobPay } from '@/features/offer';
import { Payments } from '@/features/payments';
import { Reimbursements } from '@/features/reimbursements';
import { TimeOff } from '@/features/timeOff';
import { Profile, Settings } from '@/features/user';

const App = () => (
  <MainLayout>
    <Suspense
      fallback={
        <div className="flex h-full w-full items-center justify-center">
          <Spinner size="xl" />
        </div>
      }
    >
      <Outlet />
    </Suspense>
  </MainLayout>
);

export const protectedRoutes = [
  {
    path: `${APP_BASE_PATH}/*`,
    element: <App />,
    children: [
      { path: 'personal-details', element: <Profile /> },
      { path: 'job-pay', element: <JobPay /> },
      { path: 'time-off', element: <TimeOff /> },
      { path: 'benefits', element: <Benefits /> },
      { path: 'documents', element: <Documents /> },
      { path: 'additional-compensation', element: <Reimbursements /> },
      { path: 'payments', element: <Payments /> },
      { path: 'bank-account', element: <Banking /> },
      { path: 'settings', element: <Settings /> },
      { path: '', element: <Dashboard /> },
      { path: '*', element: <Navigate to={APP_BASE_PATH} /> },
    ],
  },
  {
    path: '/*',
    element: <Navigate to={APP_BASE_PATH} />,
  },
];
