import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { DefaultMutationResponse } from '@/types';

export type NewPasswordDTO = {
  password: string;
  passwordConfirmation: string;
};

export const newPassword = async (
  data: Partial<NewPasswordDTO>
): Promise<DefaultMutationResponse> => {
  return axios.post(`${API_BASE_PATH}/onboard/password`, data);
};

type UseNewPasswordOptions = {
  config?: MutationConfig<typeof newPassword>;
};

export const useNewPassword = ({ config }: UseNewPasswordOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: newPassword,
  });
};
