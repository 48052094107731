import { ExclamationIcon } from '@heroicons/react/outline';

import { Button } from '@/components/Elements';
import { ModalDialog } from '@/components/Elements/ModalDialog';

import { useCancelAdditionalCompensation } from '../api/cancelAdditionalCompensation';

type CancelAdditionalCompensationProps = {
  id: number;
};

const triggerButton = (
  <button className="rounded-full px-2 text-froly-600 hover:text-froly-500 focus:underline focus:underline-offset-2 focus:outline-none">
    Cancel
  </button>
);

export const CancelAdditionalCompensation = ({ id }: CancelAdditionalCompensationProps) => {
  const cancelAdditionalCompensationMutation = useCancelAdditionalCompensation();

  const handleConfirmationClick = async () =>
    await cancelAdditionalCompensationMutation.mutateAsync({ id });

  return (
    <ModalDialog
      icon={<ExclamationIcon className="h-6 w-6 text-froly-600" />}
      description="This action can&rsquo;t be undone, if you change your mind you will have to create another reimbursement."
      hasCancelButton={false}
      showDismiss={true}
      confirmButton={
        <Button
          isLoading={cancelAdditionalCompensationMutation.isLoading}
          type="button"
          onClick={handleConfirmationClick}
          variant="danger"
          disabled={cancelAdditionalCompensationMutation.isLoading}
        >
          Cancel reimbursement
        </Button>
      }
      triggerButton={triggerButton}
      title="Are you sure you want to cancel this reimbursement?"
      isDone={cancelAdditionalCompensationMutation.isSuccess}
    />
  );
};
