export type Data<T> = {
  data: T;
};

export type DefaultMutationResponse = {
  message: string;
};

export interface Links {
  first: string;
  last: string;
  prev?: string;
  next?: string;
}

export interface Link {
  url: string;
  label: string;
  active: boolean;
}

export interface Meta {
  current_page: number;
  from: number;
  last_page: number;
  links: Link[];
  path: string;
  per_page: number;
  to: number;
  total: number;
}

export type TPaginatedQuery<T> = {
  data: T;
  links: Links;
  meta: Meta;
};

export enum StatusColor {
  Green = 'green',
  Yellow = 'yellow',
  Red = 'red',
  Gray = 'gray',
}

export type FileMetadata = {
  path: string;
  name: string;
};
