import { PaperClipIcon } from '@heroicons/react/outline';

import { Button, Spinner } from '@/components/Elements';
import { EmptyState } from '@/components/Elements/EmptyState';
import { SectionHeading } from '@/components/Layout/SectionHeading';
import { CANADA_BENEFITS_LINK } from '@/config';

import { useBenefits } from '../api/getBenefits';
import { Document as TDocument } from '../types';

const Entries = ({ documents }: { documents: TDocument[] }) => (
  <dd className="text-sm text-ebony-clay-900">
    <ul className="divide-y divide-ebony-clay-100 rounded-md border-ebony-clay-100 sm:border">
      {documents.map((document) => (
        <li
          key={document.name}
          className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
        >
          <div className="flex w-0 flex-1 items-center">
            <PaperClipIcon
              className="h-5 w-5 flex-shrink-0 text-ebony-clay-400"
              aria-hidden="true"
            />
            <span className="ml-2 w-0 flex-1 truncate">{document.name}</span>
          </div>
          <div className="ml-4 flex-shrink-0">
            <a
              href={document.path}
              className="font-medium text-dodger-blue-600 hover:text-dodger-blue-500"
              target="_blank"
              rel="noreferrer"
            >
              Download
            </a>
          </div>
        </li>
      ))}
    </ul>
  </dd>
);

export const Documents = () => {
  const benefitsQuery = useBenefits();

  if (benefitsQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!benefitsQuery.data) return null;

  const benefits = benefitsQuery.data.data;

  if (benefits.countryCode === 'CA' && benefits.type === 'group')
    return (
      <a href={CANADA_BENEFITS_LINK} tabIndex={-1}>
        <Button size="lg" className="w-full md:w-auto">
          Access to Benefits Overview
        </Button>
      </a>
    );

  if (benefits.documents.length < 1)
    return (
      <div className="bg-ebony-clay-50 p-12 sm:border sm:border-ebony-clay-100 sm:bg-white sm:p-24">
        <EmptyState title="No benefit documents found" />
      </div>
    );

  return (
    <div>
      <SectionHeading title="Documents" />
      <Entries documents={benefits.documents} />
    </div>
  );
};
