import { Spinner } from '@/components/Elements';
import { SectionHeading } from '@/components/Layout/SectionHeading';
import { formatDate, pluralizeWord } from '@/utils/format';

import { useOffer } from '../api/getOffer';

type EntryProps = {
  label: string;
  value: string;
};

const Entry = ({ label, value }: EntryProps) => (
  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
    <dt className="text-sm font-medium text-gray-500">{label}</dt>
    <dd className="mt-1 sm:col-span-2 sm:mt-0">
      <p className="text-sm text-gray-900 capitalize-first">{value}</p>
    </dd>
  </div>
);

export const Offer = () => {
  const offerQuery = useOffer();

  if (offerQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!offerQuery.data) return null;

  const {
    data: { offer },
  } = offerQuery.data;

  const salary = `$${offer.salary} ${offer.currencyCode} ${offer.grossSalaryPeriod}`;
  const startDate = formatDate(offer.startDate);
  const status = `${offer.employmentType} Employment`;
  const workAddress = offer.address
    ? `${offer.address.countryCode}, ${offer.address.state}, ${offer.address.city}, ${offer.address.postalCode}, ${offer.address.street}`
    : 'No address registered';
  const {
    jobTitle,
    servicesDescription: jobDescription,
    country: employmentLocation,
    employeeType,
    ptoBalance,
  } = offer;

  const totalDays =
    typeof ptoBalance?.vacationDays === 'number'
      ? `${String(ptoBalance?.vacationDays)} day${pluralizeWord(ptoBalance?.vacationDays)}`
      : '';

  return (
    <>
      <div>
        <SectionHeading title="Employment Details" />
        <dl className="sm:divide-y sm:divide-gray-200">
          <Entry label="Status" value={status} />
          <Entry label="Start date" value={startDate} />
          <Entry label="Job title" value={jobTitle} />
          <Entry label="Job description" value={jobDescription} />
          <Entry label="Work address" value={workAddress} />
          <Entry label="Employment location" value={employmentLocation} />
        </dl>
      </div>
      <div>
        <SectionHeading title="Compensation" />
        <dl className="sm:divide-y sm:divide-gray-200">
          <Entry label="Employee type" value={employeeType} />
          <Entry label="Salary" value={salary} />
        </dl>
      </div>
      {ptoBalance && (
        <div>
          <SectionHeading title="Paid time off" />
          <dl className="sm:divide-y sm:divide-gray-200">
            {ptoBalance.type && <Entry label="Policy" value={ptoBalance.type} />}
            <Entry label="Period" value={ptoBalance.period} />
            <Entry label="Total" value={totalDays} />
          </dl>
        </div>
      )}
    </>
  );
};
