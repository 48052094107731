import mixpanel from 'mixpanel-browser';

import { MIXPANEL_PROJECT_TOKEN } from '@/config';

export default function mixpanelInit(
  userId: string | null,
  profileProperties: Record<string, any>
) {
  mixpanel.init(MIXPANEL_PROJECT_TOKEN, { track_pageview: true, persistence: 'localStorage' });

  if (userId) {
    mixpanel.identify(userId);
    mixpanel.people.set(profileProperties);
  }
}

export function mixpanelReset() {
  mixpanel.reset();
}

export function mixpanelTrackSubmit(eventName: string, eventProperties: Record<string, any>) {
  mixpanel.track(eventName, eventProperties);
}
