import { windowReplaceOriginUrl } from '@/utils/url';

export const ErrorState = () => (
  <div
    className="flex h-screen w-screen flex-col items-center justify-center text-red-500"
    role="alert"
  >
    <h2 className="text-lg font-semibold">Something went wrong, please refresh!! </h2>
    <button className="mt-4" onClick={windowReplaceOriginUrl}>
      Refresh
    </button>
  </div>
);
