import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { DefaultMutationResponse } from '@/types';

export type CreateDTO = {
  payee: {
    firstName: string;
    middleName?: string;
    lastName: string;
    phoneNumber: string;
    birthdate: string;
    nationality: string;
  };
  personalIds: {
    identityId: string;
    eps?: string;
    pensionFund?: string;
    severanceFund?: string;
    federal?: string;
    provincial?: string;
    identityIdType?: string;
    taxId?: string;
    nationalId?: string;
    civilStatus?: string;
    workPermitId?: string;
    hasInfonavitLoan?: string;
    socialSecurityId?: string;
    voluntaryContributions?: string[];
    taxUsResident?: string;
    dependants?: {
      name: string;
      address: string;
      relationship: string;
    }[];
    totalClaimAmount?: {
      federal: string;
      provincial: string;
    };
  };
  address: {
    street: string;
    city: string;
    state: string;
    postalCode: string;
    countryCode: string;
  };
};

const create = (personalInformation: CreateDTO): Promise<DefaultMutationResponse> => {
  return axios.post(`${API_BASE_PATH}/onboard/create`, personalInformation, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

type UseCreateOptions = {
  config?: MutationConfig<typeof create>;
};

export const useCreate = ({ config }: UseCreateOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: create,
  });
};
