import { Pagination, Pill, Spinner, Table } from '@/components/Elements';
import { EmptyState } from '@/components/Elements/EmptyState';
import { SectionHeading } from '@/components/Layout/SectionHeading';
import { CurrencyValue } from '@/features/countries';
import { StatusColor } from '@/types';
import { formatDate } from '@/utils/format';

import { useAdditionalCompensations } from '../api/getAdditionalCompensations';
import { Data, TStatusTypes } from '../types';

import { CancelAdditionalCompensation } from './CancelAdditionalCompensation';

const Receipt = ({ receipt }: Pick<Data, 'receipt'>) => {
  if (!receipt) return null;
  return (
    <a
      href={receipt}
      className="flex items-center justify-center space-x-1 rounded-full focus:underline focus:underline-offset-2 focus:outline-none"
      download
      target="_blank"
      rel="noreferrer"
    >
      <span className="text-sm">Download</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="h-4 w-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
        />
      </svg>
    </a>
  );
};

const Status = ({ id, status, requesterType }: Pick<Data, 'id' | 'status' | 'requesterType'>) => {
  const canCancel = status === 'submitted' || status === 'accepted';
  const isWorker = requesterType === 'worker';

  if (canCancel && isWorker) return <CancelAdditionalCompensation id={id} />;

  const statusTypes: TStatusTypes = {
    accepted: StatusColor.Green,
    canceled: StatusColor.Red,
    paid: StatusColor.Gray,
    submitted: StatusColor.Gray,
    processed: StatusColor.Gray,
    rejected: StatusColor.Red,
  };

  return <Pill color={statusTypes[status as keyof TStatusTypes]}>{status}</Pill>;
};

export const AdditionalCompensations = ({
  addEntryButton,
}: {
  addEntryButton: JSX.Element | null;
}) => {
  const additionalCompensationsQuery = useAdditionalCompensations();

  if (additionalCompensationsQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!additionalCompensationsQuery.data) return null;

  const { data, links, meta } = additionalCompensationsQuery.data;

  if (meta.total < 1)
    return (
      <div className="bg-ebony-clay-50 p-12 sm:border sm:border-ebony-clay-100 sm:bg-white sm:p-24">
        <EmptyState
          title="No additional compensation requests found"
          triggerButton={addEntryButton}
        />
      </div>
    );

  return (
    <div>
      <SectionHeading actionComponents={[addEntryButton]} />
      <Table<Data>
        data={data}
        isFetching={
          additionalCompensationsQuery.isFetching && additionalCompensationsQuery.isPreviousData
        }
        columns={[
          {
            title: 'Date',
            field: 'createdAt',
            Cell: ({ entry: { createdAt } }) => <p>{formatDate(createdAt)}</p>,
          },
          {
            title: 'Type',
            field: 'type',
            Cell: ({ entry: { type } }) => <p className="capitalize-first">{type}</p>,
          },
          {
            title: 'Description',
            field: 'description',
            isFullWidth: true,
            Cell: ({ entry: { description } }) => (
              <p className="max-w-sm truncate">{description}</p>
            ),
          },
          {
            title: 'Amount',
            field: 'amount',
            Cell: ({ entry: { amount, currencyCode } }) => (
              <CurrencyValue value={amount} currencyCode={currencyCode} />
            ),
          },
          {
            title: 'Receipt',
            field: 'receipt',
            Cell: ({ entry: { receipt } }) => <Receipt receipt={receipt} />,
          },
          {
            title: 'Status',
            field: 'status',
            Cell: ({ entry: { status, id, requesterType } }) => (
              <Status status={status} id={id} requesterType={requesterType} />
            ),
          },
        ]}
        pagination={
          <Pagination
            links={links}
            meta={meta}
            path="/additional-compensation"
            isFetching={
              additionalCompensationsQuery.isFetching && additionalCompensationsQuery.isPreviousData
            }
          />
        }
      />
    </div>
  );
};
