import { cloneElement, useCallback, useEffect } from 'react';

import { useDisclosure } from '@/hooks/useDisclosure';

import { Button } from '../Elements/Button';
import { Drawer, DrawerProps } from '../Elements/Drawer';

type FormDrawerProps = {
  isDone: boolean;
  triggerButton: React.ReactElement;
  submitButton: React.ReactElement;
  title: string;
  children: React.ReactNode;
  size?: DrawerProps['size'];
  onDrawerClose?: () => void;
};

export const FormDrawer = ({
  title,
  children,
  isDone,
  triggerButton,
  submitButton,
  size = 'md',
  onDrawerClose,
}: FormDrawerProps) => {
  const { close, open, isOpen } = useDisclosure();

  const handleDrawerClose = useCallback(() => {
    const isAccepted = window.confirm(
      'Are you sure you want to close this form? Changes will be lost.'
    );

    if (!isAccepted) return;

    if (onDrawerClose) {
      onDrawerClose();
    }

    close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDone) close();
  }, [isDone, close]);

  return (
    <>
      {cloneElement(triggerButton, { onClick: open })}
      <Drawer
        isOpen={isOpen}
        onClose={handleDrawerClose}
        title={title}
        size={size}
        renderFooter={() => (
          <div className="flex w-full flex-col gap-3 md:flex-row md:justify-end">
            <Button
              className="flex-1 md:flex-initial"
              variant="secondary"
              onClick={handleDrawerClose}
            >
              Cancel
            </Button>
            {cloneElement(submitButton, { className: 'flex-1 md:flex-initial' })}
          </div>
        )}
      >
        {children}
      </Drawer>
    </>
  );
};
