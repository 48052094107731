import { useQuery } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Data } from '@/types';

import { TBenefits } from '../types';

export const getBenefits = (): Promise<Data<TBenefits>> => axios.get(`${API_BASE_PATH}/benefits`);

type QueryFnType = typeof getBenefits;

type UseBenefitsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useBenefits = ({ config }: UseBenefitsOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['benefits'],
    queryFn: () => getBenefits(),
  });
};
