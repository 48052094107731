import { Link } from '@/components/Elements';
import { useAuth } from '@/lib/auth';

import {
  CaBankingForm,
  CoBankingForm,
  DefaultBankingForm,
  GlobalBankingForm,
  MxBankingForm,
} from '../components/Banking';
import { Layout } from '../components/Layout';

const renderBankingForm = (offerType?: string, offerCountryCode?: string) => {
  if (offerType === 'contractor') return <DefaultBankingForm />;
  if (offerCountryCode === 'CA') return <CaBankingForm />;
  if (offerCountryCode === 'CO') return <CoBankingForm />;
  if (offerCountryCode === 'MX') return <MxBankingForm />;
  return <GlobalBankingForm />;
};

export const Banking = () => {
  const { user } = useAuth();

  return (
    <Layout
      backButton={
        <Link className="text-lg" to="/onboarding/personal-information">
          &larr; {'Back'}
        </Link>
      }
    >
      {renderBankingForm(user?.data.config.offerType, user?.data.config.offerCountryCode)}
    </Layout>
  );
};
