import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { TTimeOff } from '../types';

export const cancelTimeOff = ({ id }: { id: number }) =>
  axios.patch(`${API_BASE_PATH}/time-off/${id}`);

type UseCancelTimeOffOptions = {
  config?: MutationConfig<typeof cancelTimeOff>;
};

export const useCancelTimeOff = ({ config }: UseCancelTimeOffOptions = {}) => {
  return useMutation({
    onMutate: async (cancelTimeOff) => {
      await queryClient.cancelQueries('time-off');
      await queryClient.cancelQueries('time-off-balance');

      const previousTimeOffs = queryClient.getQueryData<TTimeOff>('time-off');

      queryClient.setQueryData(
        'time-off',
        previousTimeOffs?.data?.filter((timeOff) => timeOff.id !== cancelTimeOff.id)
      );

      return { previousTimeOffs };
    },
    onError: (_, __, context: any) => {
      if (context?.previousTimeOffs) {
        queryClient.setQueryData('time-off', context.previousTimeOffs);
      }
    },
    onSuccess: () => queryClient.invalidateQueries('time-off'),
    ...config,
    mutationFn: cancelTimeOff,
  });
};
