import { Navigate, Route, Routes } from 'react-router-dom';

import { useAuth } from '@/lib/auth';

import { Banking } from './Banking';
import { Documentation } from './Documentation';
import { Finished } from './Finished';
import { NewPassword } from './NewPassword';
import { PersonalInformation } from './PersonalInformation';

const Filled = () => (
  <Routes>
    <Route path="onboarding/finished" element={<Finished />} />
    <Route path="*" element={<Navigate to="onboarding/finished" />} />
  </Routes>
);

const PasswordStep = () => (
  <Routes>
    <Route path="onboarding/password" element={<NewPassword />} />
    <Route path="*" element={<Navigate to="onboarding/password" />} />
  </Routes>
);

export const OnboardingRoutes = () => {
  const { user } = useAuth();

  if (user?.data.status === 'filled') return <Filled />;
  if (!user?.data.config.passwordSet) return <PasswordStep />;

  return (
    <Routes>
      <Route path="onboarding/personal-information" element={<PersonalInformation />} />
      <Route path="onboarding/banking" element={<Banking />} />
      <Route path="onboarding/documentation" element={<Documentation />} />
      <Route path="*" element={<Navigate to="onboarding/personal-information" />} />
    </Routes>
  );
};
