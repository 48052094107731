import { useQuery } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Data } from '@/types';

interface Information {
  payee: Payee;
  address: Address;
  bankDetails: BankDetails;
  bankAddress: Address;
  personalIds: PersonalIds;
  config: Config;
}

interface Config {
  offerCountryCode: string;
  offerCurrencyCode: string;
  offerType: string;
  offerCountryActive: boolean;
  passwordSet: boolean;
}

interface PersonalIds {
  taxId: string;
  identityId: string;
  nationalId: string;
  civilStatus: string;
  workPermitId: string;
  hasInfonavitLoan: string;
  socialSecurityId: string;
  voluntaryContributions: string[];
  totalClaimAmount?: {
    federal: string;
    provincial: string;
  };
}

interface BankDetails {
  countryCode: string;
  currencyCode: string;
  name: string;
  status: string;
  clabe: string;
  account: string;
  type: string;
  swiftBic: string;
}

interface Address {
  countryCode: string;
  state: string;
  city: string;
  postalCode: string;
  street: string;
}

interface Payee {
  firstName: string;
  middleName?: string;
  lastName: string;
  birthdate: string;
  phoneNumber: string;
  nationality: string;
}

export const information = (): Promise<Data<Information>> =>
  axios.get(`${API_BASE_PATH}/onboard/information`);

type QueryFnType = typeof information;

type UseInformationOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useInformation = ({ config }: UseInformationOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['information'],
    queryFn: () => information(),
  });
};
