import { useQuery } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Data } from '@/types';

import { PersonalInformation } from '../types';

export const personalInformation = (): Promise<Data<PersonalInformation>> =>
  axios.get(`${API_BASE_PATH}/personal-information`);

type QueryFnType = typeof personalInformation;

type UsePersonalInformationOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const usePersonalInformation = ({ config }: UsePersonalInformationOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['personal-information'],
    queryFn: () => personalInformation(),
  });
};
