export type Country = {
  name: string;
  code: string;
  currency: string;
  available: boolean;
};

type TServiceCountry = {
  countryCode: string;
  currencyCode: string;
  name: string;
};

type TServiceCountryKeys = 'ca' | 'co' | 'eu' | 'mx' | 'us';

export const serviceCountries: Record<TServiceCountryKeys, TServiceCountry> = {
  ca: { countryCode: 'CA', currencyCode: 'CAD', name: 'Canada' },
  co: { countryCode: 'CO', currencyCode: 'COP', name: 'Colombia' },
  eu: { countryCode: 'EU', currencyCode: 'EUR', name: 'Europe' },
  mx: { countryCode: 'MX', currencyCode: 'MXN', name: 'Mexico' },
  us: { countryCode: 'US', currencyCode: 'USD', name: 'United States Of America' },
} as const;
