import { initReactQueryAuth } from 'react-query-auth';

import { Spinner } from '@/components/Elements';
import { NODE_ENV } from '@/config';
import {
  getUser,
  LoginCredentialsDTO,
  loginWithEmailAndPassword,
  logout,
  User,
} from '@/features/auth';
import { mixpanelReset } from '@/lib/mixpanel';
import { Data } from '@/types';
import { windowReplaceOriginUrl } from '@/utils/url';

const loadUser = async () => await getUser();

const loginFn = async (data: LoginCredentialsDTO) => {
  const response = await loginWithEmailAndPassword(data);

  if (response !== undefined) {
    windowReplaceOriginUrl();
  }

  return null;
};

const logoutFn = async () => {
  await logout();
  if (NODE_ENV === 'production') {
    mixpanelReset();
  }
};

const registerFn = async () => null;

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <Spinner size="xl" />
      </div>
    );
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth<
  Data<User> | null,
  unknown,
  LoginCredentialsDTO,
  unknown
>(authConfig);
