import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { Data, FileMetadata } from '@/types';

export type UploadFileDTO = {
  file: File;
};

export const uploadFile = ({ file }: UploadFileDTO): Promise<Data<FileMetadata>> => {
  const formData = new FormData();
  formData.append('file', file);

  return axios.post('/uploads', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

type UseUploadFileOptions = {
  config?: MutationConfig<typeof uploadFile>;
};

export const useUploadFile = ({ config }: UseUploadFileOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: (data) => {
      if (data) {
        addNotification({
          type: 'success',
          title: 'Successful upload!',
        });
      }
    },
    ...config,
    mutationFn: uploadFile,
  });
};
