import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { AdditionalCompensations } from '../types';

export const getAdditionalCompensations = (page: string): Promise<AdditionalCompensations> =>
  axios.get(`${API_BASE_PATH}/payroll/additional-compensations`, {
    params: {
      page,
    },
  });

type QueryFnType = typeof getAdditionalCompensations;

type UseAdditionalCompensationsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useAdditionalCompensations = ({ config }: UseAdditionalCompensationsOptions = {}) => {
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page') || '1';

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['additional-compensations', page],
    queryFn: () => getAdditionalCompensations(page),
  });
};
