import { PropsWithChildren, ReactNode } from 'react';

import logo from '@/assets/via-logo-black.png';
import { Link, Well } from '@/components/Elements';
import { Head } from '@/components/Head';
import { useAuth } from '@/lib/auth';

type LayoutProps = {
  backButton?: ReactNode;
  title?: string;
  withSignOut?: boolean;
};

export const Layout = ({
  children,
  backButton,
  title,
  withSignOut = true,
}: PropsWithChildren<LayoutProps>) => {
  const { logout, isLoggingOut } = useAuth();
  const handleSignOut = () => logout();

  return (
    <>
      <Head title={title} />
      <div className="flex h-auto flex-col justify-center pt-4 pb-8 sm:px-6 lg:mt-12 lg:px-8">
        <div className="mb-16 sm:mx-auto sm:w-full sm:max-w-md">
          <Link to="/">
            <img className="mx-auto h-12 w-auto" src={logo} alt="VIA" />
          </Link>
        </div>
        <main className="mx-auto w-full p-4 sm:p-0 md:max-w-4xl lg:max-w-5xl">
          <div className="mb-4 flex justify-between">
            {backButton}
            {withSignOut && (
              <button
                disabled={isLoggingOut}
                onClick={handleSignOut}
                className="text-froly-500 hover:text-froly-400 focus:underline focus:underline-offset-2 focus:outline-none"
              >
                Sign out
              </button>
            )}
          </div>
          <Well>{children}</Well>
        </main>
      </div>
    </>
  );
};
