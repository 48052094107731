import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { Paystubs } from '../types';

export const getPaystubs = (page: string): Promise<Paystubs> =>
  axios.get(`${API_BASE_PATH}/paystubs`, {
    params: {
      page,
    },
  });

type QueryFnType = typeof getPaystubs;

type UsePaystubsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const usePaystubs = ({ config }: UsePaystubsOptions) => {
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page') || '1';

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['paystubs', page],
    queryFn: () => getPaystubs(page),
  });
};
