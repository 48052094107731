import { PaperClipIcon } from '@heroicons/react/outline';

import { Spinner } from '@/components/Elements';
import { EmptyState } from '@/components/Elements/EmptyState';
import { SectionHeading } from '@/components/Layout/SectionHeading';

import { useDocuments } from '../api/getDocuments';

type DocumentProps = {
  name: string;
  path: string;
};

const Entries = ({ documents }: { documents: DocumentProps[] }) => (
  <dd className="text-sm text-ebony-clay-900">
    <ul className="divide-y divide-ebony-clay-100 rounded-md border-ebony-clay-100 sm:border">
      {documents.map((document) => (
        <li
          key={document.name}
          className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
        >
          <div className="flex w-0 flex-1 items-center">
            <PaperClipIcon
              className="h-5 w-5 flex-shrink-0 text-ebony-clay-400"
              aria-hidden="true"
            />
            <span className="ml-2 w-0 flex-1 truncate">{document.name}</span>
          </div>
          <div className="ml-4 flex-shrink-0">
            <a
              href={document.path}
              className="font-medium text-dodger-blue-600 hover:text-dodger-blue-500"
              target="_blank"
              rel="noreferrer"
            >
              Download
            </a>
          </div>
        </li>
      ))}
    </ul>
  </dd>
);

export const EmploymentDocuments = () => {
  const documentsQuery = useDocuments();

  if (documentsQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!documentsQuery.data) return null;

  const documents = documentsQuery.data.data;

  if (documents.length < 1)
    return (
      <div className="bg-ebony-clay-50 p-12 sm:border sm:border-ebony-clay-100 sm:bg-white sm:p-24">
        <EmptyState title="No employment documents found" />
      </div>
    );

  return (
    <div>
      <SectionHeading title="Employment Documents" />
      <Entries documents={documents} />
    </div>
  );
};
