import { Well } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { PageHeading } from '@/components/Layout/PageHeading';

import { PersonalInformation } from '../components/PersonalInformation';

const title = 'Personal details';

export const Profile = () => (
  <ContentLayout title={title}>
    <Well>
      <>
        <PageHeading title={title} />
        <PersonalInformation />
      </>
    </Well>
  </ContentLayout>
);
