import { Well } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { PageHeading } from '@/components/Layout/PageHeading';

import { Offer } from '../components/Offer';

const title = 'Job & Pay';

export const JobPay = () => (
  <ContentLayout title={title}>
    <Well>
      <>
        <PageHeading title={title} />
        <Offer />
      </>
    </Well>
  </ContentLayout>
);
