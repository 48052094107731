import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField, PasswordRequirements } from '@/components/Form';
import { windowReplaceOriginUrl } from '@/utils/url';
import { isValidStrongPassword } from '@/utils/validate';

import { useNewPassword } from '../api/newPassword';

const schema = z
  .object({
    password: z
      .string()
      .min(1, 'Required')
      .refine(isValidStrongPassword, "Password doesn't meet requirements"),
    passwordConfirmation: z.string().min(1, 'Required'),
  })
  .refine(({ password, passwordConfirmation }) => password === passwordConfirmation, {
    path: ['passwordConfirmation'],
    message: 'Confirmation password should match password',
  });

type LoginValuesSchema = z.infer<typeof schema>;

export const NewPasswordForm = () => {
  const newPassword = useNewPassword();

  const handleOnSubmit = async (values: LoginValuesSchema) => {
    await newPassword.mutateAsync(values, {
      onSettled: (data) => {
        if (data) windowReplaceOriginUrl();
      },
    });
  };

  return (
    <div>
      <Form<LoginValuesSchema, typeof schema>
        onSubmit={async (payee) => {
          handleOnSubmit(payee);
        }}
        schema={schema}
        className="space-y-8 divide-y divide-gray-200"
      >
        {({ register, formState }) => (
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Create your password
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Start by selecting a secure password. The password requirements are listed at the
                  end of this section. Don’t use a password from another website, and avoid
                  including any private information like your name or birthday.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <InputField
                    type="password"
                    label="Password"
                    error={formState.errors['password']}
                    registration={register('password', { deps: 'passwordConfirmation' })}
                  />
                </div>
                <div className="sm:col-span-3">
                  <InputField
                    label="Confirm Password"
                    error={formState.errors['passwordConfirmation']}
                    registration={register('passwordConfirmation', { deps: 'password' })}
                    type="password"
                  />
                </div>
              </div>
              <PasswordRequirements />
            </div>
            <div className="pt-5">
              <div className="flex justify-end">
                <Button
                  isLoading={newPassword.isLoading}
                  disabled={newPassword.isLoading}
                  type="submit"
                  size="lg"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};
