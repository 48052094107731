import { cloneElement } from 'react';

import { SelectFieldProps } from '@/components/Form';
import { swiftBicCodes } from '@/utils/format';

export const bankNamesWithSwift: Record<string, string | null> = {
  'Banco Agrario De Colombia S.A.': 'CCAICOBBXXX',
  'Banco Bilbao Vizcaya Argentaria Colombia S.A.': 'GEROCOBBXXX',
  'Banco Caja Social': 'CASOCOBBXXX',
  'Banco Comercial Av Villas S.A.': 'BAVICOBBXXX',
  'Banco Davivienda S.A.(Formerly Granbanco Bancafe S.A.)': 'CAFECOBBXXX',
  'Banco De Bogota': 'BBOGCOBBXXX',
  'Banco De Occidente': 'OCCICOBCXXX',
  'Banco Gnb Sudameris': 'BSUDCOBBXXX',
  'Banco J.P. Morgan Colombia S.A.': 'CHASCOBBXXX',
  'Banco Pichincha S.A.': 'PICHCOBBXXX',
  'Banco Popular Sa - Colombia': 'BPOPCOBBXXX',
  'Banco Santander De Negocios Colombia S.A.': 'SANTCOBBXXX',
  'Banco Serfinanza S.A.': 'SERZCOBBXXX',
  'Bancolombia S.A.': 'COLOCOBMXXX',
  'Bbva Asset Management S.A., Sociedad Fiduciaria': 'FBBVCOBBXXX',
  'Bnp Paribas Colombia Corporacion Financiera S.A.': 'BNPACOBBXXX',
  'Citibank Colombia': 'CITICOBBXXX',
  'Coltefinanciera S.A. Compania De Financiamiento Comercial': 'CLTECOBMXXX',
  'Corporacion Financiera Colombiana (Formerly Corporacion Financiera Del Valle S.A.)':
    'CFVACOBCXXX',
  'Itau Corpbanca Colombia S.A.': 'BCTOCOBBXXX',
  'Porvenir S.A.': 'PORVCOBBXXX',
  'Scotiabank Colpatria S.A.': 'COLPCOBBXXX',
  'Banco Falabella S.A': 'FALACLRMXXX',
  'Hsbc Colombia , S.A.': 'BISTCOBBXXX',
  'Banco Finandina S.A.': null,
  'Banco Multibank S.A.': null,
  'Banco Mundo Mujer': null,
  Confiar: null,
  Daviplata: null,
  Movii: null,
  Nequi: null,
  Rappipay: null,
} as const;

export const colombiaSwiftBicCode = (bankName?: string): string =>
  swiftBicCodes(bankNamesWithSwift, bankName || '');

export const ColombiaBankSelect = ({ children }: { children: JSX.Element }) => {
  const options = Object.keys(bankNamesWithSwift)
    .map((key) => ({
      label: key,
      value: key,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  return cloneElement<SelectFieldProps>(children, {
    options: [{ label: '', value: '' }, ...options],
  });
};
