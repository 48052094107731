import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
  accept: string;
};

export const UploadField = (props: InputFieldProps) => {
  const { label, helperText, registration, error, accept } = props;
  return (
    <FieldWrapper label={label} error={error} helperText={helperText}>
      <input
        id="file"
        type="file"
        accept={accept}
        className={clsx(
          'flex w-full rounded-3xl border border-gray-300 bg-white px-3 py-2 text-sm shadow-sm',
          'file:cursor-pointer file:border-0 file:bg-transparent file:text-sm file:font-medium',
          'focus-within:outline-none focus-within:ring-2 focus-within:ring-inset focus-within:ring-black',
          'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-black',
          'cursor-pointer disabled:cursor-not-allowed disabled:opacity-50'
        )}
        {...registration}
      />
    </FieldWrapper>
  );
};
