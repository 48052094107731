import { useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { Button, Spinner } from '@/components/Elements';
import { Form, InputField, SelectField } from '@/components/Form';
import { BetaCountrySelect } from '@/features/misc';
import { useAuth } from '@/lib/auth';
import { mixpanelTrackSubmit } from '@/lib/mixpanel';
import { isValidInt } from '@/utils/validate';

import { BankDetailsDTO, useBankDetails } from '../../api/bankDetails';
import { useInformation } from '../../api/getInformation';
import { State } from '../State';

const detailsSchema = z.object({
  account: z
    .string()
    .trim()
    .min(7, 'Account number must contain at least 7 digits')
    .max(12, 'Account number must contain at most 12 digits')
    .refine(isValidInt),
  institutionNumber: z
    .string()
    .trim()
    .min(1, 'Required')
    .length(3, 'Institution number should be 3 digits')
    .refine(isValidInt),
  transitCode: z
    .string()
    .trim()
    .min(1, 'Required')
    .length(5, 'Transit number should be 5 digits')
    .refine(isValidInt),
  type: z.string().min(1, 'Required'),
});

const addressSchema = z.object({
  street: z.string().trim().min(1, 'Required'),
  city: z.string().trim().min(1, 'Required'),
  state: z.string().trim().min(1, 'Required'),
  postalCode: z.string().trim().min(1, 'Required'),
  bankCountryCode: z.string().trim().min(1, 'Required'),
});

const caBankingSchema = z.object({
  ...detailsSchema.shape,
  ...addressSchema.shape,
});

export type TCaBankingSchema = z.infer<typeof caBankingSchema>;

const bankDetailsDTO = (values: TCaBankingSchema): BankDetailsDTO => {
  return {
    bankDetails: {
      account: values.account,
      countryCode: 'CA',
      currencyCode: 'CAD',
      type: values.type,
      transitCode: values.transitCode,
      institutionNumber: values.institutionNumber,
    },
    bankAddress: {
      street: values.street,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      countryCode: values.bankCountryCode,
    },
  };
};

export const CaBankingForm = () => {
  const navigate = useNavigate();

  const onboardingInformationQuery = useInformation();
  const bankDetailsMutation = useBankDetails();
  const { user } = useAuth();

  const handleOnSubmit = async (values: TCaBankingSchema) => {
    await bankDetailsMutation.mutateAsync(bankDetailsDTO(values), {
      onSettled: (data) => {
        if (data) navigate('/onboarding/documentation');
      },
    });
    mixpanelTrackSubmit('submit_bank_details', {
      $worker_type: user?.data.config.offerType,
      $company: user?.data.config.offerCompany,
      $start_date: user?.data.config.offerStartAt,
    });
  };

  if (onboardingInformationQuery.isLoading)
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner size="lg" />
      </div>
    );

  const onboardingInformation = onboardingInformationQuery.data?.data;

  const defaultValues: Partial<TCaBankingSchema> = {
    ...onboardingInformation?.bankAddress,
    ...onboardingInformation?.bankDetails,
    bankCountryCode: onboardingInformation?.bankAddress.countryCode,
  };

  return (
    <div>
      <Form<TCaBankingSchema, typeof caBankingSchema>
        onSubmit={async (payee) => {
          handleOnSubmit(payee);
        }}
        schema={caBankingSchema}
        className="space-y-8 divide-y divide-gray-200"
        options={{ defaultValues, shouldUnregister: true }}
      >
        {({ formState, register, resetField, watch }) => (
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Bank Account Details
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Please enter the account where you’d like to receive your payments.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-2">
                  <InputField
                    label="Account number"
                    error={formState.errors['account']}
                    registration={register('account')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="Institution number"
                    error={formState.errors['institutionNumber']}
                    registration={register('institutionNumber')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="Transit number"
                    error={formState.errors['transitCode']}
                    registration={register('transitCode')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <SelectField
                    label="Account type"
                    error={formState.errors['type']}
                    registration={register('type')}
                    options={[
                      { label: '', value: '' },
                      { label: 'Checking', value: 'checking' },
                      { label: 'Savings', value: 'saving' },
                    ]}
                  />
                </div>
              </div>
            </div>

            <div className="pt-8">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Bank Account Address
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Please enter the address tied to your bank account.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <InputField
                    label="Street"
                    error={formState.errors['street']}
                    registration={register('street')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="City"
                    error={formState.errors['city']}
                    registration={register('city')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <State countryCode={watch('bankCountryCode')} />
                </div>
                <div className="sm:col-span-1">
                  <InputField
                    label="Postal code"
                    error={formState.errors['postalCode']}
                    registration={register('postalCode')}
                  />
                </div>
                <div className="sm:col-span-3">
                  <BetaCountrySelect
                    label="Country"
                    error={formState.errors['bankCountryCode']}
                    registration={register('bankCountryCode', {
                      onChange: () => {
                        resetField('state', { defaultValue: '' });
                      },
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-end">
                <Button
                  isLoading={bankDetailsMutation.isLoading}
                  disabled={bankDetailsMutation.isLoading}
                  type="submit"
                  size="lg"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};
