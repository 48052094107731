import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, UploadField } from '@/components/Form';
import { useAuth } from '@/lib/auth';
import { mixpanelTrackSubmit } from '@/lib/mixpanel';
import { windowReplaceOriginUrl } from '@/utils/url';
import { hasFirstItem, isValidFile } from '@/utils/validate';

import { UploadFilesDTO, useUploadFiles } from '../../api/uploadFiles';

const defaultDocumentationSchema = z.object({
  identityId: z
    .instanceof(FileList)
    .refine((fileList) => hasFirstItem(fileList), 'Required')
    .refine(isValidFile, 'File should be less than 20mb'),
  nationalId: z
    .instanceof(FileList)
    .refine((fileList) => hasFirstItem(fileList), 'Required')
    .refine(isValidFile, 'File should be less than 20mb')
    .optional(),
  bankStatement: z
    .instanceof(FileList)
    .refine((fileList) => hasFirstItem(fileList), 'Required')
    .refine(isValidFile, 'File should be less than 20mb'),
});

export type TDefaultDocumentationSchema = z.infer<typeof defaultDocumentationSchema>;

const documentationDTO = (values: TDefaultDocumentationSchema): UploadFilesDTO => {
  return {
    data: {
      identityId: values.identityId.item(0),
      nationalId: values.nationalId?.item(0),
      bankStatement: values.bankStatement.item(0),
    },
  };
};

export const DefaultDocumentationForm = () => {
  const { user } = useAuth();
  const uploadFilesMutation = useUploadFiles();

  const offerCountryCode = user?.data.config.offerCountryCode;
  const isBR = offerCountryCode === 'BR';

  const handleOnSubmit = async (values: TDefaultDocumentationSchema) => {
    await uploadFilesMutation.mutateAsync(documentationDTO(values), {
      onSettled: (data) => {
        if (data) windowReplaceOriginUrl();
      },
    });
    mixpanelTrackSubmit('submit_documentation_proof', {
      $worker_type: user?.data.config.offerType,
      $company: user?.data.config.offerCompany,
      $start_date: user?.data.config.offerStartAt,
    });
  };

  return (
    <div>
      <Form<TDefaultDocumentationSchema, typeof defaultDocumentationSchema>
        onSubmit={async (payee) => {
          handleOnSubmit(payee);
        }}
        schema={defaultDocumentationSchema}
        className="space-y-8 divide-y divide-gray-200"
      >
        {({ register, formState }) => (
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">Documentation</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Please upload the required files. Max 20MB per file.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <UploadField
                    label="Document ID"
                    error={formState.errors['identityId']}
                    registration={register('identityId')}
                    accept=".jpg, .jpeg, .png, .pdf"
                  />
                </div>
                <div className="sm:col-span-3">
                  <UploadField
                    label="Bank statement"
                    error={formState.errors['bankStatement']}
                    registration={register('bankStatement')}
                    accept=".jpg, .jpeg, .png, .pdf"
                    helperText="account number + relevant information must be visible"
                  />
                </div>
                {isBR && (
                  <div className="sm:col-span-3">
                    <UploadField
                      label="National ID"
                      error={formState.errors['nationalId']}
                      registration={register('nationalId')}
                      accept=".jpg, .jpeg, .png, .pdf"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-end">
                <Button
                  isLoading={uploadFilesMutation.isLoading}
                  disabled={uploadFilesMutation.isLoading}
                  type="submit"
                  size="lg"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};
