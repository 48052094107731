import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { DefaultMutationResponse } from '@/types';

export type BankDetailsDTO = {
  bankDetails: {
    account?: string;
    accountLocation?: string;
    clabe?: string;
    countryCode: string;
    currencyCode: string;
    institutionNumber?: string;
    name?: string;
    routingNumber?: string;
    swiftBic?: string;
    transitCode?: string;
    type?: string;
  };
  bankAddress: {
    street: string;
    city: string;
    state: string;
    postalCode: string;
    countryCode: string;
  };
};

const bankDetails = (bankDetails: BankDetailsDTO): Promise<DefaultMutationResponse> => {
  return axios.post(`${API_BASE_PATH}/onboard/bank-details`, bankDetails, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

type UseBankDetailsOptions = {
  config?: MutationConfig<typeof bankDetails>;
};

export const useBankDetails = ({ config }: UseBankDetailsOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: bankDetails,
  });
};
