import { Well } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { PageHeading } from '@/components/Layout/PageHeading';

import { AdditionalCompensations } from '../components/AdditionalCompensations';
import { CreateAdditionalCompensations } from '../components/CreateAdditionalCompensations/CreateAdditionalCompensations';

const title = 'Additional Compensation';

export const Reimbursements = () => (
  <ContentLayout title={title}>
    <Well>
      <>
        <PageHeading title={title} />
        <AdditionalCompensations
          addEntryButton={
            <CreateAdditionalCompensations key="ReimbursementsCreateAdditionalCompensations" />
          }
        />
      </>
    </Well>
  </ContentLayout>
);
