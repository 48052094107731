export const API_BASE_PATH = '/worker';
export const API_URL = process.env.REACT_APP_API_URL as string;
export const APP_BASE_PATH = '/worker';
export const CSRF_PATH = '/sanctum/csrf-cookie';
export const MAX_IMAGE_SIZE_BYTES = 2e7;
export const CANADA_BENEFITS_LINK = 'https://platform.risepeople.com/';
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN as string;
export const NODE_ENV = process.env.NODE_ENV;
export const HOTJAR_HJID = process.env.REACT_APP_HOTJAR_HJID as string;
export const HOTJAR_HJSV = process.env.REACT_APP_HOTJAR_HJSV as string;
export const MIXPANEL_PROJECT_TOKEN = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN as string;
export const US_STATES = [
  {
    title: 'Alabama',
    code: 'AL',
  },
  {
    title: 'Alaska',
    code: 'AK',
  },
  {
    title: 'Arizona',
    code: 'AZ',
  },
  {
    title: 'Arkansas',
    code: 'AR',
  },
  {
    title: 'California',
    code: 'CA',
  },
  {
    title: 'Colorado',
    code: 'CO',
  },
  {
    title: 'Connecticut',
    code: 'CT',
  },
  {
    title: 'Delaware',
    code: 'DE',
  },
  {
    title: 'District of Columbia',
    code: 'DC',
  },
  {
    title: 'Florida',
    code: 'FL',
  },
  {
    title: 'Georgia',
    code: 'GA',
  },
  {
    title: 'Guam',
    code: 'GU',
  },
  {
    title: 'Hawaii',
    code: 'HI',
  },
  {
    title: 'Idaho',
    code: 'ID',
  },
  {
    title: 'Illinois',
    code: 'IL',
  },
  {
    title: 'Indiana',
    code: 'IN',
  },
  {
    title: 'Iowa',
    code: 'IA',
  },
  {
    title: 'Kansas',
    code: 'KS',
  },
  {
    title: 'Kentucky',
    code: 'KY',
  },
  {
    title: 'Louisiana',
    code: 'LA',
  },
  {
    title: 'Maine',
    code: 'ME',
  },
  {
    title: 'Maryland',
    code: 'MD',
  },
  {
    title: 'Massachusetts',
    code: 'MA',
  },
  {
    title: 'Michigan',
    code: 'MI',
  },
  {
    title: 'Minnesota',
    code: 'MN',
  },
  {
    title: 'Mississippi',
    code: 'MS',
  },
  {
    title: 'Missouri',
    code: 'MO',
  },
  {
    title: 'Montana',
    code: 'MT',
  },
  {
    title: 'Nebraska',
    code: 'NE',
  },
  {
    title: 'Nevada',
    code: 'NV',
  },
  {
    title: 'New Hampshire',
    code: 'NH',
  },
  {
    title: 'New Jersey',
    code: 'NJ',
  },
  {
    title: 'New Mexico',
    code: 'NM',
  },
  {
    title: 'New York',
    code: 'NY',
  },
  {
    title: 'North Carolina',
    code: 'NC',
  },
  {
    title: 'North Dakota',
    code: 'ND',
  },
  {
    title: 'Northern Mariana Islands',
    code: 'MP',
  },
  {
    title: 'Ohio',
    code: 'OH',
  },
  {
    title: 'Oklahoma',
    code: 'OK',
  },
  {
    title: 'Oregon',
    code: 'OR',
  },
  {
    title: 'Pennsylvania',
    code: 'PA',
  },
  {
    title: 'Puerto Rico',
    code: 'PR',
  },
  {
    title: 'Rhode Island',
    code: 'RI',
  },
  {
    title: 'South Carolina',
    code: 'SC',
  },
  {
    title: 'South Dakota',
    code: 'SD',
  },
  {
    title: 'Tennessee',
    code: 'TN',
  },
  {
    title: 'Texas',
    code: 'TX',
  },
  {
    title: 'Utah',
    code: 'UT',
  },
  {
    title: 'Vermont',
    code: 'VT',
  },
  {
    title: 'Virgin Islands',
    code: 'VI',
  },
  {
    title: 'Virginia',
    code: 'VA',
  },
  {
    title: 'Washington',
    code: 'WA',
  },
  {
    title: 'West Virginia',
    code: 'WV',
  },
  {
    title: 'Wisconsin',
    code: 'WI',
  },
  {
    title: 'Wyoming',
    code: 'WY',
  },
];

export const ZERO_DECIMAL_CURRENCIES = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
]; // [1]

/**
 * References:
 * [1] https://stripe.com/docs/currencies#zero-decimal
 * We are referring to a common currency/money api for standards
 */
