import { Layout } from '../components/Layout';
import { NewPasswordForm } from '../components/NewPasswordForm';

export const NewPassword = () => {
  return (
    <Layout>
      <NewPasswordForm />
    </Layout>
  );
};
