import { useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { Button, Spinner } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { BetaCountrySelect } from '@/features/misc';
import { useAuth } from '@/lib/auth';
import { mixpanelTrackSubmit } from '@/lib/mixpanel';

import { CreateDTO, useCreate } from '../../api/create';
import { useInformation } from '../../api/getInformation';
import { State } from '../State';

const personalInformationSchema = z.object({
  firstName: z.string().trim().min(1, 'Required'),
  middleName: z.string().trim().optional(),
  lastName: z.string().trim().min(1, 'Required'),
  phoneNumber: z.string().trim().min(1, 'Required'),
  birthdate: z.string().trim().min(1, 'Required'),
  nationality: z.string().trim().min(1, 'Required'),
});

const personalIdsSchema = z.object({
  identityId: z.string().trim().min(1, 'Required'),
});

const addressSchema = z.object({
  street: z.string().trim().min(1, 'Required'),
  city: z.string().trim().min(1, 'Required'),
  state: z.string().trim().min(1, 'Required'),
  postalCode: z.string().trim().min(1, 'Required'),
  countryCode: z.string().trim().min(1, 'Required'),
});

const globalPersonalInformationSchema = z.object({
  ...personalInformationSchema.shape,
  ...personalIdsSchema.shape,
  ...addressSchema.shape,
});

export type TGlobalPersonalInformationSchema = z.infer<typeof globalPersonalInformationSchema>;

const createDTO = (values: TGlobalPersonalInformationSchema): CreateDTO => {
  return {
    payee: {
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      birthdate: values.birthdate,
      nationality: values.nationality,
    },
    address: {
      street: values.street,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      countryCode: values.countryCode,
    },
    personalIds: {
      identityId: values.identityId,
    },
  };
};

export const GlobalPersonalInformationForm = () => {
  const navigate = useNavigate();

  const onboardingInformationQuery = useInformation();
  const createMutation = useCreate();
  const { user } = useAuth();

  const handleOnSubmit = async (values: TGlobalPersonalInformationSchema) => {
    await createMutation.mutateAsync(createDTO(values), {
      onSettled: (data) => {
        if (data) navigate('/onboarding/banking');
      },
    });
    mixpanelTrackSubmit('submit_personal_information', {
      $worker_type: user?.data.config.offerType,
      $company: user?.data.config.offerCompany,
      $start_date: user?.data.config.offerStartAt,
    });
  };

  if (onboardingInformationQuery.isLoading)
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner size="lg" />
      </div>
    );

  const onboardingInformation = onboardingInformationQuery.data?.data;

  const defaultValues: Partial<TGlobalPersonalInformationSchema> = {
    ...onboardingInformation?.address,
    ...onboardingInformation?.payee,
    ...onboardingInformation?.personalIds,
  };

  return (
    <Form<TGlobalPersonalInformationSchema, typeof globalPersonalInformationSchema>
      onSubmit={async (payee) => {
        handleOnSubmit(payee);
      }}
      schema={globalPersonalInformationSchema}
      className="space-y-8 divide-y divide-gray-200"
      options={{
        defaultValues,
        shouldUnregister: true,
      }}
    >
      {({ formState, register, resetField, watch }) => (
        <div className="space-y-8 divide-y divide-gray-200">
          <div>
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
              <p className="mt-1 text-sm text-gray-500">
                Please fill in your personal details as they appear on your government issued ID.
              </p>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <InputField
                  label="First name"
                  error={formState.errors['firstName']}
                  registration={register('firstName')}
                />
              </div>
              <div className="sm:col-span-2">
                <InputField
                  label="Middle name (optional)"
                  error={formState.errors['middleName']}
                  registration={register('middleName')}
                />
              </div>
              <div className="sm:col-span-2">
                <InputField
                  label="Last name"
                  error={formState.errors['lastName']}
                  registration={register('lastName')}
                  helperText="Exactly as it appears on your government issued ID"
                />
              </div>
              <div className="sm:col-span-2">
                <InputField
                  label="Phone number"
                  error={formState.errors['phoneNumber']}
                  registration={register('phoneNumber')}
                />
              </div>
              <div className="sm:col-span-2">
                <InputField
                  label="Birth date"
                  type="date"
                  error={formState.errors['birthdate']}
                  registration={register('birthdate')}
                />
              </div>
              <div className="sm:col-span-4">
                <BetaCountrySelect
                  label="Nationality"
                  error={formState.errors['nationality']}
                  registration={register('nationality')}
                />
              </div>
            </div>
          </div>
          <div className="pt-8">
            <div>
              <p className="mt-1 text-sm text-gray-500">
                Please fill in your personal ID information.
              </p>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <InputField
                  label="Document ID number"
                  error={formState.errors['identityId']}
                  registration={register('identityId')}
                  helperText="Passport"
                />
              </div>
            </div>
          </div>

          <div className="pt-8">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">Address</h3>
              <p className="mt-1 text-sm text-gray-500">
                Please enter the address you will be working from.
              </p>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <InputField
                  label="Street"
                  error={formState.errors['street']}
                  registration={register('street')}
                />
              </div>
              <div className="sm:col-span-2">
                <InputField
                  label="City"
                  error={formState.errors['city']}
                  registration={register('city')}
                />
              </div>
              <div className="sm:col-span-2">
                <State countryCode={watch('countryCode')} />
              </div>
              <div className="sm:col-span-1">
                <InputField
                  label="Postal code"
                  error={formState.errors['postalCode']}
                  registration={register('postalCode')}
                />
              </div>
              <div className="sm:col-span-3">
                <BetaCountrySelect
                  label="Country"
                  error={formState.errors['countryCode']}
                  registration={register('countryCode', {
                    onChange: () => {
                      resetField('state', { defaultValue: '' });
                    },
                  })}
                />
              </div>
            </div>
          </div>
          <div className="pt-5">
            <div className="flex justify-end">
              <Button
                isLoading={createMutation.isLoading}
                disabled={createMutation.isLoading}
                type="submit"
                size="lg"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};
