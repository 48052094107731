import { Spinner } from '@/components/Elements';
import { SectionHeading } from '@/components/Layout/SectionHeading';
import { formatDate } from '@/utils/format';

import { usePersonalInformation } from '../api/getPersonalInformation';

type EntryProps = {
  label: string;
  value: string;
};

const Entry = ({ label, value }: EntryProps) => (
  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
    <dt className="text-sm font-medium text-gray-500">{label}</dt>
    <dd className="mt-1 sm:col-span-2 sm:mt-0">
      <p className="text-sm text-gray-900">{value}</p>
    </dd>
  </div>
);

export const PersonalInformation = () => {
  const personalInformationQuery = usePersonalInformation();

  if (personalInformationQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!personalInformationQuery.data) return null;

  const { data: personalInformation } = personalInformationQuery.data;

  return (
    <div>
      <SectionHeading title="Basics" />
      <dl className="sm:divide-y sm:divide-gray-200">
        <Entry label="Full name" value={personalInformation.fullName} />
        <Entry label="Birth date" value={formatDate(personalInformation.birthDate)} />
        <Entry label="Personal email address" value={personalInformation.email} />
        <Entry label="Phone number" value={personalInformation.phoneNumber} />
        <Entry label="National ID number" value={personalInformation.nationalId} />
        <Entry label="Nationality" value={personalInformation.country.name} />
      </dl>
    </div>
  );
};
