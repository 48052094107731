import { useQuery } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Data } from '@/types';

import { BankAccount } from '../types';

export const bankAccount = (): Promise<Data<BankAccount>> =>
  axios.get(`${API_BASE_PATH}/bank-account`);

type QueryFnType = typeof bankAccount;

type UseBankAccountOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useBankAccount = ({ config }: UseBankAccountOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['bank-account'],
    queryFn: () => bankAccount(),
  });
};
