import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, UploadField } from '@/components/Form';
import { useAuth } from '@/lib/auth';
import { mixpanelTrackSubmit } from '@/lib/mixpanel';
import { windowReplaceOriginUrl } from '@/utils/url';
import { hasFirstItem, isValidFile } from '@/utils/validate';

import { useInformation } from '../../api/getInformation';
import { UploadFilesDTO, useUploadFiles } from '../../api/uploadFiles';

const mxDocumentationSchema = z.object({
  identityId: z
    .instanceof(FileList)
    .refine((fileList) => hasFirstItem(fileList), 'Required')
    .refine(isValidFile, 'File should be less than 20mb'),
  proofOfAddress: z
    .instanceof(FileList)
    .refine((fileList) => hasFirstItem(fileList), 'Required')
    .refine(isValidFile, 'File should be less than 20mb'),
  bankStatement: z
    .instanceof(FileList)
    .refine((fileList) => hasFirstItem(fileList), 'Required')
    .refine(isValidFile, 'File should be less than 20mb'),
  taxId: z
    .instanceof(FileList)
    .refine((fileList) => hasFirstItem(fileList), 'Required')
    .refine(isValidFile, 'File should be less than 20mb'),
  workPermit: z
    .instanceof(FileList)
    .refine((fileList) => hasFirstItem(fileList), 'Required')
    .refine(isValidFile, 'File should be less than 20mb')
    .optional(),
});

export type TMxDocumentationSchema = z.infer<typeof mxDocumentationSchema>;

const documentationDTO = (values: TMxDocumentationSchema): UploadFilesDTO => {
  return {
    data: {
      identityId: values.identityId.item(0),
      proofOfAddress: values.proofOfAddress.item(0),
      bankStatement: values.bankStatement.item(0),
      taxId: values.taxId.item(0),
      workPermit: values.workPermit?.item(0),
    },
  };
};

export const MxDocumentationForm = () => {
  const uploadFilesMutation = useUploadFiles();
  const onboardingInformationQuery = useInformation();
  const { user } = useAuth();

  const handleOnSubmit = async (values: TMxDocumentationSchema) => {
    await uploadFilesMutation.mutateAsync(documentationDTO(values), {
      onSettled: (data) => {
        if (data) windowReplaceOriginUrl();
      },
    });
    mixpanelTrackSubmit('submit_documentation_proof', {
      $worker_type: user?.data.config.offerType,
      $company: user?.data.config.offerCompany,
      $start_date: user?.data.config.offerStartAt,
    });
  };

  const onboardingInformation = onboardingInformationQuery.data?.data;
  const nationality = onboardingInformation?.payee.nationality;
  const offerCountryCode = onboardingInformation?.config.offerCountryCode;
  const needsPermit = nationality !== offerCountryCode;

  return (
    <Form<TMxDocumentationSchema, typeof mxDocumentationSchema>
      onSubmit={async (payee) => {
        handleOnSubmit(payee);
      }}
      schema={mxDocumentationSchema}
      className="space-y-8 divide-y divide-gray-200"
    >
      {({ register, formState }) => (
        <div className="space-y-8 divide-y divide-gray-200">
          <div>
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">Documentation</h3>
              <p className="mt-1 text-sm text-gray-500">
                Please upload the required files. Max 20MB per file.
              </p>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <UploadField
                  label="Document ID"
                  error={formState.errors['identityId']}
                  registration={register('identityId')}
                  accept=".jpg, .jpeg, .png, .pdf"
                  helperText="INE, Passport, etc."
                />
              </div>
              <div className="sm:col-span-3">
                <UploadField
                  label="Bank statement"
                  error={formState.errors['bankStatement']}
                  registration={register('bankStatement')}
                  accept=".jpg, .jpeg, .png, .pdf"
                  helperText="Account number + relevant information must be visible"
                />
              </div>
              <div className="sm:col-span-3">
                <UploadField
                  label="Proof of address"
                  error={formState.errors['proofOfAddress']}
                  registration={register('proofOfAddress')}
                  accept=".jpg, .jpeg, .png, .pdf"
                />
              </div>
              <div className="sm:col-span-3">
                <UploadField
                  label="Tax ID number"
                  error={formState.errors['taxId']}
                  registration={register('taxId')}
                  accept=".jpg, .jpeg, .png, .pdf"
                  helperText="Comprobante de situación fiscal"
                />
              </div>
              {needsPermit && (
                <div className="sm:col-span-3">
                  <UploadField
                    label="Work permit"
                    error={formState.errors['workPermit']}
                    registration={register('workPermit')}
                    accept=".jpg, .jpeg, .png, .pdf"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <Button
                isLoading={uploadFilesMutation.isLoading}
                disabled={uploadFilesMutation.isLoading}
                type="submit"
                size="lg"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};
