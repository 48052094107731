import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { DefaultMutationResponse } from '@/types';

export type UploadFilesDTO = {
  data: {
    identityId: File | null;
    nationalId?: File | null;
    proofOfAddress?: File | null;
    bankStatement: File | null;
    taxId?: File | null;
    pensionFund?: File | null;
    severanceFund?: File | null;
    eps?: File | null;
    workPermit?: File | null;
    fpv?: File | null;
    afc?: File | null;
    hic?: File | null;
    edc?: File | null;
  };
};

export const uploadFiles = ({ data }: UploadFilesDTO): Promise<DefaultMutationResponse> => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    if (value) formData.append(key, value);
  }

  return axios.post(`${API_BASE_PATH}/onboard/files`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

type UseUploadFilesOptions = {
  config?: MutationConfig<typeof uploadFiles>;
};

export const useUploadFiles = ({ config }: UseUploadFilesOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: uploadFiles,
  });
};
