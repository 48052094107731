import { Dialog, Transition } from '@headlessui/react';
import { MenuAlt2Icon } from '@heroicons/react/outline';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import logo from '@/assets/via-logo-black.png';
import { MergerBanner } from '@/features/misc';
import { useDisclosure } from '@/hooks/useDisclosure';

import { MobileSidebar } from './MobileSidebar';
import { DesktopSideNavigation } from './Navigation';
import { ProfileMenu } from './ProfileMenu';

const TopBar = ({ onHamburguerClick }: { onHamburguerClick: () => void }) => (
  <>
    {/* <=md */}
    <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border border-ebony-clay-100 bg-white shadow-sm sm:shadow-none lg:hidden">
      <button
        type="button"
        className="px-4 text-ebony-clay-500 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-ebony-clay-100 lg:hidden"
        onClick={onHamburguerClick}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex flex-1 items-center justify-end px-4">
        <div className="flex rounded-3xl p-3">
          <ProfileMenu />
        </div>
      </div>
    </div>
    {/* >= lg */}
    <div className="sticky top-0 z-10 ml-auto hidden px-4 lg:block">
      <div className="mt-6 flex rounded-3xl border bg-white p-3 shadow-sm">
        <ProfileMenu />
      </div>
    </div>
  </>
);

const Overlay = () => (
  <Transition.Child
    as={Fragment}
    enter="transition-opacity ease-linear duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition-opacity ease-linear duration-300"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
  </Transition.Child>
);

const Logo = () => {
  return (
    <Link to="." tabIndex={-1}>
      <div className="flex flex-shrink-0 items-center px-4">
        <img className="h-8 w-auto" src={logo} alt="VIA" />
        <span className="text-xl font-semibold text-white">VIA</span>
      </div>
    </Link>
  );
};

const MailSupport = () => (
  <a
    className="
    group
    flex
    items-center
    rounded-md
    px-2
    py-2
    text-sm
    font-medium
    leading-5
    text-ebony-clay-600
    transition
    duration-150
    ease-in-out
    hover:bg-ebony-clay-50
    hover:text-ebony-clay-900
    focus:text-ebony-clay-900
    focus:outline-none
  "
    target="_blank"
    href="mailto:support@via.work"
    rel="noreferrer"
  >
    <svg
      className="
      mr-3
      h-6
      w-6
      text-ebony-clay-400
      transition
      duration-150
      ease-in-out
      group-hover:text-ebony-clay-500
      group-focus:text-ebony-clay-500
    "
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
    Have a question? Contact us at support@via.work at any time.
  </a>
);

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const { close, open, isOpen } = useDisclosure();

  return (
    <div>
      <div className="z-20 flex h-14 w-full items-center justify-center bg-dodger-blue-700 text-base text-white lg:fixed lg:left-0 lg:top-0">
        <MergerBanner />
      </div>
      {/* Sidebar for mobile */}
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={close}>
          <Overlay />
          <MobileSidebar close={close} logo={<Logo />} />
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="mt-auto hidden h-[calc(100%-56px)] lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
          <Logo />
          <div className="mt-5 flex flex-grow flex-col">
            <DesktopSideNavigation />
            <MailSupport />
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="flex flex-1 flex-col lg:pl-64">
        <TopBar onHamburguerClick={open} />
        {children}
      </div>
    </div>
  );
};
