import { useRoutes } from 'react-router-dom';

import { NODE_ENV } from '@/config';
import { ResetPassword, User } from '@/features/auth';
import { useAuth } from '@/lib/auth';
import hotjarInit from '@/lib/hotjar';
import mixpanelInit from '@/lib/mixpanel';
import { Data } from '@/types';

import { onboardingRoutes } from './onboarding';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

const createRoutes = (user?: Data<User> | null) => {
  if (user) {
    return user.data.status === 'active' || user.data.status === 'terminated'
      ? protectedRoutes
      : onboardingRoutes;
  } else {
    return publicRoutes;
  }
};

export const AppRoutes = () => {
  const auth = useAuth();
  const commonRoutes = [{ path: 'auth/password/reset', element: <ResetPassword /> }];

  if (NODE_ENV === 'production') {
    hotjarInit(String(auth.user?.data.id), {
      mail: auth.user?.data.email,
    });

    mixpanelInit(String(auth.user?.data.id), {
      $first_name: auth.user?.data.firstName,
      $last_name: auth.user?.data.lastName,
      $email: auth.user?.data.email,
    });
  }

  const routes = createRoutes(auth.user);
  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
