import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { TTimeOff } from '../types';

export const getTimeOff = (page: string): Promise<TTimeOff> =>
  axios.get(`${API_BASE_PATH}/time-off`, {
    params: {
      page,
    },
  });

type QueryFnType = typeof getTimeOff;

type UseTimeOffOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useTimeOff = ({ config }: UseTimeOffOptions = {}) => {
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page') || '1';

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['time-off', page],
    queryFn: () => getTimeOff(page),
  });
};
