import { Pill, Spinner } from '@/components/Elements';
import { SectionHeading } from '@/components/Layout/SectionHeading';
import { useAuth } from '@/lib/auth';
import { StatusColor } from '@/types';

import { useBankAccount } from '../api/getBankAccount';
import { BankAccount as TBankAccount } from '../types';

import { UpdateBankAccount } from './UpdateBankAccount';

type EntryProps = {
  label: string;
  children: React.ReactNode;
};

const Entry = ({ label, children }: EntryProps) => (
  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
    <dt className="text-sm font-medium text-gray-500">{label}</dt>
    <dd className="mt-1 sm:col-span-2 sm:mt-0">
      <p className="text-sm text-gray-900">{children}</p>
    </dd>
  </div>
);

const Details = ({ details }: { details: TBankAccount['details'] }) => (
  <>
    {details?.account && <Entry label="Account">{details?.account}</Entry>}
    {details?.clabe && <Entry label="CLABE">{details?.clabe}</Entry>}
    {details?.institutionNumber && (
      <Entry label="Institution Number">{details?.institutionNumber}</Entry>
    )}
    {details?.transitCode && <Entry label="Transit Code">{details?.transitCode}</Entry>}
    {details?.type && <Entry label="Account Type">{details?.type}</Entry>}
  </>
);

export const BankAccount = () => {
  const { user } = useAuth();
  const bankAccountQuery = useBankAccount();

  if (bankAccountQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!bankAccountQuery.data) return null;

  const { data: bankAccount } = bankAccountQuery.data;

  return (
    <>
      {bankAccount.details !== null && (
        <div>
          <SectionHeading title="Information" />
          <dl className="sm:divide-y sm:divide-gray-200">
            <Details details={bankAccount.details} />
            <Entry label="Status">
              {bankAccount.status === 'active' ? (
                <Pill color={StatusColor.Green}>{bankAccount.status}</Pill>
              ) : (
                <Pill color={StatusColor.Yellow}>{bankAccount.status}</Pill>
              )}
            </Entry>
          </dl>
        </div>
      )}
      {user?.data.status !== 'terminated' && <UpdateBankAccount workerType={bankAccount.type} />}
    </>
  );
};
