import isAfter from 'validator/es/lib/isAfter';
import isAlphanumeric from 'validator/es/lib/isAlphanumeric';
import isBIC from 'validator/es/lib/isBIC';
import isCurrency, { IsCurrencyOptions } from 'validator/es/lib/isCurrency';
import isInt, { IsIntOptions } from 'validator/es/lib/isInt';
import isStrongPassword from 'validator/es/lib/isStrongPassword';

import { MAX_IMAGE_SIZE_BYTES, ZERO_DECIMAL_CURRENCIES } from '@/config';

export const isValidBic = (value: string) => isBIC(value);
export const isValidInt = (value: string, options?: IsIntOptions) => isInt(value, options);
export const isAfterDate = (str: string, date?: string) => isAfter(str, date);

export const isValidIban = () => new RegExp(/^[a-zA-Z]{2}[a-zA-Z0-9 ]{12,40}$/);
export const isValidUsIban = () => new RegExp(/^[a-zA-Z0-9]{4,34}$/);

export const isValidCurrency = (
  amount: string,
  currencyCode: string,
  options?: IsCurrencyOptions
) => {
  const allowDecimal = !ZERO_DECIMAL_CURRENCIES.includes(currencyCode);
  return isCurrency(amount, { ...options, allow_negatives: false, allow_decimal: allowDecimal });
};

export const isValidStrongPassword: typeof isStrongPassword = (value, options) =>
  isStrongPassword(value, {
    minLength: 8,
    minLowercase: 1,
    minSymbols: 1,
    minUppercase: 1,
    minNumbers: 1,
    ...options,
  });

export const isValidAlphanumeric: typeof isAlphanumeric = (values) => isAlphanumeric(values);

export const hasFirstItem = (fileList: FileList): boolean => (fileList.item(0) ? true : false);

export const isValidFile = (fileList: FileList) => {
  if (fileList.length === 0) {
    return false;
  } else if (fileList[0].size < MAX_IMAGE_SIZE_BYTES) {
    return true;
  } else {
    return false;
  }
};
