import { LibraryIcon } from '@heroicons/react/outline';

import { Button } from '@/components/Elements';
import { BarCta, FormDrawer } from '@/components/Form';
import { parsedCountryCode } from '@/utils/format';

import { UpdateBankAccountDTO, useUpdateBankAccount } from '../../api/updateBankAccount';

import { ActionForm } from './action';
import { DefaultUpdateSchema } from './schema';

const updateBankAccountDTO = (values: DefaultUpdateSchema): UpdateBankAccountDTO => {
  return {
    bankAddress: {
      city: values.city,
      country: values.country,
      postalCode: values.postalCode,
      state: values.state,
      street: values.street,
    },
    bankDetails: {
      countryCode: values.countryCode || parsedCountryCode(values.currencyCode),
      currencyCode: values.currencyCode,
      account: values.account,
      clabe: values.clabe,
      name: values.name,
      transitCode: values.transitCode,
      institutionNumber: values.institutionNumber,
      routingNumber: values.routingNumber,
      type: values.type,
      swiftBic: values.swiftBic,
    },
    bankStatement: values.bankStatement[0],
  };
};

export const DefaultUpdate = () => {
  const updateBankAccountMutation = useUpdateBankAccount();

  const handleOnSubmit = async (values: DefaultUpdateSchema) => {
    await updateBankAccountMutation.mutateAsync(updateBankAccountDTO(values));
  };

  return (
    <FormDrawer
      isDone={updateBankAccountMutation.isSuccess}
      triggerButton={
        <BarCta
          logo={
            <div>
              <LibraryIcon width={24} height={24} />
            </div>
          }
          title="Bank account"
          description="Update your bank account info"
        />
      }
      title="Bank Account Details"
      submitButton={
        <Button
          form="default-update"
          type="submit"
          isLoading={updateBankAccountMutation.isLoading}
          disabled={updateBankAccountMutation.isLoading}
        >
          Submit
        </Button>
      }
    >
      <ActionForm onSubmit={handleOnSubmit} />
    </FormDrawer>
  );
};
