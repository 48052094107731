import { XCircleIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';

import { Spinner } from '@/components/Elements';
import { FileMetadata } from '@/types';

type UploadedFilesProps = {
  setFiles: React.Dispatch<React.SetStateAction<FileMetadata[]>>;
  files: FileMetadata[];
  isDisabled: boolean;
};

export const UploadedFiles = ({ files, setFiles, isDisabled }: UploadedFilesProps) => {
  const lastDivRef = useRef<HTMLDivElement>(null);

  const deleteFile = (filePath: string) => () => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.path !== filePath));
  };

  useEffect(() => {
    if (lastDivRef.current) {
      lastDivRef.current.focus();
    }
  }, [files]);

  if (files.length <= 0) return null;

  return (
    <div className="mt-4 divide-y rounded-lg bg-ebony-clay-50 px-4 py-1 text-sm">
      {files.map((file, index) => {
        const ref = index === files.length - 1 ? lastDivRef : null;

        return (
          <div key={file.path} className="flex justify-between py-3" tabIndex={-1} ref={ref}>
            <span className={clsx(!file.path && 'animate-pulse text-ebony-clay-500')}>
              {file.name}
            </span>
            {files.find((value) => value.path === '') ? (
              <Spinner size="xs" variant="primary" className="mr-0.5" />
            ) : (
              <button
                onClick={deleteFile(file.path)}
                disabled={isDisabled}
                className="text-froly-500 disabled:text-ebony-clay-300"
              >
                <XCircleIcon className={clsx('h-4 w-4')} />
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};
