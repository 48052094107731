import { ChangeEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { InputField, SelectField } from '@/components/Form';
import { ColombiaBankSelect, colombiaSwiftBicCode } from '@/features/countries';
import { useAuth } from '@/lib/auth';

import { DefaultUpdateSchema } from '../schema';

export const ColombiaForm = () => {
  const { user } = useAuth();
  const { formState, register, resetField, watch, setValue } =
    useFormContext<DefaultUpdateSchema>();

  const currencyCode = watch('currencyCode');
  const bankName = watch('name');

  const isContractor = user?.data.config.offerType === 'contractor';
  const bankHasSwiftCode = Boolean(colombiaSwiftBicCode(bankName));

  useEffect(() => {
    resetField('type', { defaultValue: '' });
  }, [currencyCode, resetField]);

  return (
    <>
      <ColombiaBankSelect>
        <SelectField
          label="Financial institution"
          error={formState.errors['name']}
          registration={register('name', {
            onChange: (event: ChangeEvent<HTMLSelectElement>) => {
              if (isContractor) {
                setValue('swiftBic', colombiaSwiftBicCode(event.currentTarget.value) || undefined, {
                  shouldValidate: true,
                });
              }
            },
          })}
          options={[{ label: '', value: '' }]}
        />
      </ColombiaBankSelect>
      <div className="flex w-full space-x-4">
        {isContractor && bankHasSwiftCode && (
          <div className="flex-1">
            <InputField
              label="SWIFT / BIC code"
              error={formState.errors['swiftBic']}
              registration={register('swiftBic')}
              helperText={
                <p>
                  Please confirm that your <span className="font-medium"> SWIFT / BIC</span> code is
                  accurate before clicking
                  <span className="font-medium"> Submit</span>
                </p>
              }
            />
          </div>
        )}
        <div className="flex-1">
          <SelectField
            label="Account type"
            error={formState.errors['type']}
            registration={register('type')}
            options={[
              { label: '', value: '' },
              { label: 'Current', value: 'current' },
              { label: 'Savings', value: 'savings' },
            ]}
          />
        </div>
      </div>
      <InputField
        label="Transit and account number"
        error={formState.errors['account']}
        registration={register('account')}
      />
    </>
  );
};
