import { useQuery } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Data } from '@/types';

import { Country } from '../types';

export const getCountries = (): Promise<Data<Country[]>> => axios.get(`${API_BASE_PATH}/countries`);

type QueryFnType = typeof getCountries;

type UseCountriesOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useCountries = ({ config }: UseCountriesOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['countries'],
    queryFn: () => getCountries(),
  });
};
