import { ChangeEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { InputField, SelectField } from '@/components/Form';
import { ColombiaBankSelect, colombiaSwiftBicCode } from '@/features/countries';
import { CountrySelect } from '@/features/misc';

import { DefaultUpdateSchema } from '../schema';

export const EuropeForm = () => {
  const { formState, register, watch, resetField, setValue } =
    useFormContext<DefaultUpdateSchema>();

  const accountType = watch('type');
  const currencyCode = watch('currencyCode');
  const countryCode = watch('countryCode');

  const isColombia = countryCode === 'CO';

  useEffect(() => {
    resetField('type', { defaultValue: '' });
  }, [currencyCode, resetField]);

  return (
    <>
      <div className="flex w-full space-x-4">
        <div className="flex-1">
          <SelectField
            label="Account type"
            error={formState.errors['type']}
            registration={register('type')}
            options={[
              { label: '', value: '' },
              { label: 'Inside Europe', value: 'inside' },
              { label: 'Outside Europe', value: 'outside' },
            ]}
          />
        </div>
        <div className="flex-1">
          <CountrySelect>
            <SelectField
              label="Bank account country"
              error={formState.errors['countryCode']}
              registration={register('countryCode')}
              options={[{ label: '', value: '' }]}
            />
          </CountrySelect>
        </div>
      </div>

      {accountType === 'inside' && (
        <InputField
          label="IBAN"
          error={formState.errors['account']}
          registration={register('account')}
        />
      )}

      {accountType === 'outside' && (
        <>
          {isColombia ? (
            <ColombiaBankSelect>
              <SelectField
                label="Financial institution"
                error={formState.errors['name']}
                registration={register('name', {
                  onChange: (event: ChangeEvent<HTMLSelectElement>) => {
                    setValue('swiftBic', colombiaSwiftBicCode(event.currentTarget.value), {
                      shouldValidate: true,
                    });
                  },
                })}
                options={[{ label: '', value: '' }]}
              />
            </ColombiaBankSelect>
          ) : (
            <InputField
              label="Financial institution"
              error={formState.errors['name']}
              helperText="ex., Chase, TD Bank, HSBC, Bancolombia, CITI"
              registration={register('name')}
            />
          )}

          <InputField
            label="IBAN / Account number"
            error={formState.errors['account']}
            registration={register('account')}
          />
          <InputField
            label="SWIFT / BIC code"
            error={formState.errors['swiftBic']}
            registration={register('swiftBic')}
            helperText={
              <p>
                Please confirm that your <span className="font-medium"> SWIFT / BIC</span> code is
                accurate before clicking
                <span className="font-medium"> Submit</span>
              </p>
            }
          />
        </>
      )}
    </>
  );
};
