import { ChevronRightIcon } from '@heroicons/react/outline';

type EntryProps = {
  logo: React.SVGProps<SVGSVGElement> | JSX.Element;
  title: string;
  description?: string;
  onClick?: () => void;
};

export const BarCta = ({ logo, title, description, onClick }: EntryProps) => (
  <div
    onKeyDown={onClick}
    role="button"
    tabIndex={0}
    onClick={onClick}
    className="bg-white px-6 py-5 focus:outline-none sm:flex sm:cursor-pointer sm:items-start sm:justify-between sm:rounded-3xl sm:border sm:border-ebony-clay-200 sm:hover:border-gray-400 sm:focus:ring-2 sm:focus:ring-inset sm:focus:ring-black"
  >
    <h4 className="sr-only">{title}</h4>
    <div className="flex w-full items-center">
      <>{logo}</>
      <div className="flex w-full items-center justify-between">
        <div className="ml-4">
          <span className="text-sm font-medium text-gray-900">{title}</span>
          {description && (
            <div className="mt-1 flex items-center text-sm text-gray-500">
              <span>{description}</span>
            </div>
          )}
        </div>
        <ChevronRightIcon className="h-4 w-4" />
      </div>
    </div>
  </div>
);
