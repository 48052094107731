import * as z from 'zod';

import {
  hasFirstItem,
  isValidBic,
  isValidFile,
  isValidIban,
  isValidInt,
  isValidUsIban,
} from '@/utils/validate';

const accountLocation = z.string().trim().min(1, 'Required').optional();
const clabe = z
  .string()
  .trim()
  .length(18, 'CLABE should be 18 digits')
  .refine(isValidInt, { message: 'CLABE should be numeric' })
  .optional();
const countryCode = z.string().trim().min(1, 'Required').optional();
const institutionNumber = z
  .string()
  .trim()
  .min(1, 'Required')
  .length(3, 'Institution number should be 3 digits')
  .refine(isValidInt)
  .optional();
const name = z.string().trim().min(1, 'Required').optional();
const routingNumber = z
  .string()
  .trim()
  .length(9, 'Routing number should be 9 digits')
  .refine(isValidInt, { message: 'Routing number should be numeric' })
  .optional();
const swiftBic = z.string().trim().min(1, 'Required').optional();
const transitCode = z
  .string()
  .trim()
  .min(1, 'Required')
  .length(5, 'Transit number should be 5 digits')
  .refine(isValidInt)
  .optional();
const type = z.string().trim().min(1, 'Required').optional();

const canadaSchema = {
  institutionNumber,
  transitCode,
  type,
};

const colombiaSchema = {
  institutionNumber,
  swiftBic,
  type,
};

const europeSchema = {
  countryCode,
  swiftBic,
  type,
};

const mexicoSchema = {
  clabe,
  name,
};

const usaSchema = {
  accountLocation,
  routingNumber,
  type,
};

const commonSchema = {
  account: z.string().trim().min(1, 'Required').optional(),
  city: z.string().trim().min(1, 'Required'),
  country: z.string().trim().min(1, 'Required'),
  currencyCode: z.string().trim().min(1, 'Required'),
  postalCode: z.string().trim().min(1, 'Required'),
  state: z.string().trim().min(1, 'Required'),
  street: z.string().trim().min(1, 'Required'),
  bankStatement: z
    .instanceof(FileList)
    .refine((bankStatement) => hasFirstItem(bankStatement), 'Required')
    .refine(isValidFile, 'File should be less than 20mb'),
};

export const refineAccount = ({ account = '', accountLocation = '', currencyCode = '' }) => {
  switch (currencyCode) {
    case 'EUR':
      return isValidIban().test(account);
    case 'USD':
      if (accountLocation === 'swift') {
        return isValidUsIban().test(account);
      } else {
        return z.string().min(4).max(17).refine(isValidInt).safeParse(account).success;
      }
    case 'COP':
      return z.string().min(4).max(30).refine(isValidInt).safeParse(account).success;
    case 'CAD':
      return z.string().min(7).max(12).refine(isValidInt).safeParse(account).success;
    case 'MXN':
      return z.string().min(1).refine(isValidInt).safeParse(account).success;

    default:
      return false;
  }
};

export const schema = z
  .object({
    ...canadaSchema,
    ...colombiaSchema,
    ...commonSchema,
    ...europeSchema,
    ...mexicoSchema,
    ...usaSchema,
  })
  .refine(
    ({ currencyCode, account = '', accountLocation }) =>
      refineAccount({ currencyCode, account, accountLocation }),
    {
      path: ['account'],
      message: 'The account number is invalid',
    }
  )
  .refine(
    ({ swiftBic }) => {
      if (swiftBic) return isValidBic(swiftBic);
      return true;
    },
    {
      path: ['swiftBic'],
      message: 'The SWIFT / BIC code is invalid',
    }
  );

export type DefaultUpdateSchema = z.infer<typeof schema>;
