import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { TTimeOff } from '../types';

export type RequestTimeOffDTO = {
  requestType: string;
  startDate: string;
  endDate?: string;
  description: string;
};

export const requestTimeOff = (data: RequestTimeOffDTO): Promise<TTimeOff> => {
  const parsedData = {
    ...data,
    description: data.description || undefined,
    endDate: data.endDate || undefined,
  } as RequestTimeOffDTO;
  return axios.post(`${API_BASE_PATH}/time-off`, parsedData);
};

type UseRequestTimeOffOptions = {
  config?: MutationConfig<typeof requestTimeOff>;
};

export const useRequestTimeOff = ({ config }: UseRequestTimeOffOptions = {}) => {
  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('time-off');
      await queryClient.cancelQueries('time-off-balance');

      const previousTimeOff = queryClient.getQueryData<TTimeOff>('time-off');

      return { previousTimeOff };
    },
    onError: (_, __, context: any) => {
      if (context?.previousTimeOff) {
        queryClient.setQueryData('time-off', context.previousTimeOff);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('time-off');
    },
    ...config,
    mutationFn: requestTimeOff,
  });
};
