import { useFieldArray, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { Button, Spinner } from '@/components/Elements';
import { Form, InputField, SelectField } from '@/components/Form';
import { BetaCountrySelect } from '@/features/misc';
import { useAuth } from '@/lib/auth';
import { mixpanelTrackSubmit } from '@/lib/mixpanel';

import { CreateDTO, useCreate } from '../../api/create';
import { useInformation } from '../../api/getInformation';
import { State } from '../State';

const personalInformationSchema = z.object({
  firstName: z.string().trim().min(1, 'Required'),
  middleName: z.string().trim().optional(),
  lastName: z.string().trim().min(1, 'Required'),
  phoneNumber: z.string().trim().min(1, 'Required'),
  birthdate: z.string().trim().min(1, 'Required'),
  nationality: z.string().trim().min(1, 'Required'),
  dependants: z
    .array(
      z.object({
        name: z.string().trim().min(1, 'Required'),
        address: z.string().trim().min(1, 'Required'),
        relationship: z.string().trim().min(1, 'Required'),
      })
    )
    .optional(),
});

const personalIdsSchema = z.object({
  taxId: z
    .string()
    .trim()
    .min(1, 'Required')
    .regex(
      /^([a-z]{3,4})(\d{2})(\d{2})(\d{2})([0-9a-z]{3})$/i,
      'RFC: Please match the requested format'
    ),
  identityId: z.string().min(1, 'Required'),
  nationalId: z.string().min(1, 'Required'),
  civilStatus: z.string().min(1, 'Required'),
  workPermitId: z.string().min(1, 'Required').optional(),
  hasInfonavitLoan: z.string().min(1, 'Required'),
  socialSecurityId: z.string().min(1, 'Required'),
});

const addressSchema = z.object({
  street: z.string().min(1, 'Required'),
  city: z.string().min(1, 'Required'),
  state: z.string().min(1, 'Required'),
  postalCode: z.string().min(1, 'Required'),
  countryCode: z.string().min(1, 'Required'),
});

const mxPersonalInformationSchema = z.object({
  ...personalInformationSchema.shape,
  ...personalIdsSchema.shape,
  ...addressSchema.shape,
});

export type TMxPersonalInformationSchema = z.infer<typeof mxPersonalInformationSchema>;

const createDTO = (values: TMxPersonalInformationSchema): CreateDTO => {
  return {
    payee: {
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      birthdate: values.birthdate,
      nationality: values.nationality,
    },
    address: {
      street: values.street,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      countryCode: values.countryCode,
    },
    personalIds: {
      taxId: values.taxId.toUpperCase(),
      dependants: values.dependants,
      identityId: values.identityId,
      nationalId: values.nationalId,
      civilStatus: values.civilStatus,
      workPermitId: values.workPermitId,
      hasInfonavitLoan: values.hasInfonavitLoan,
      socialSecurityId: values.socialSecurityId,
    },
  };
};

const Dependants = () => {
  const { control, register, formState } = useFormContext<TMxPersonalInformationSchema>();

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'dependants',
  });

  const appendDependant = () => append({ address: '', name: '', relationship: '' });

  return (
    <>
      <ul>
        {fields.map((item, index) => {
          const dependantError = formState.errors.dependants && formState.errors.dependants[index];
          const removeDependant = () => remove(index);

          return (
            <li key={item.id} className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <InputField
                  label="Name"
                  error={dependantError?.name}
                  registration={register(`dependants.${index}.name` as const)}
                />
              </div>
              <div className="sm:col-span-2">
                <InputField
                  label="Address"
                  error={dependantError?.address}
                  registration={register(`dependants.${index}.address` as const)}
                />
              </div>
              <div className="sm:col-span-1">
                <InputField
                  label="Relationship"
                  error={dependantError?.relationship}
                  registration={register(`dependants.${index}.relationship` as const)}
                />
              </div>
              <div className="mt-6 sm:col-span-1">
                <Button className="w-full" variant="danger" type="button" onClick={removeDependant}>
                  Delete
                </Button>
              </div>
            </li>
          );
        })}
      </ul>
      <Button className="mt-8 w-full sm:w-auto" onClick={appendDependant}>
        Add dependant
      </Button>
    </>
  );
};

export const MxPersonalInformationForm = () => {
  const navigate = useNavigate();

  const onboardingInformationQuery = useInformation();
  const createMutation = useCreate();
  const { user } = useAuth();

  const handleOnSubmit = async (values: TMxPersonalInformationSchema) => {
    await createMutation.mutateAsync(createDTO(values), {
      onSettled: (data) => {
        if (data) navigate('/onboarding/banking');
      },
    });
    mixpanelTrackSubmit('submit_personal_information', {
      $worker_type: user?.data.config.offerType,
      $company: user?.data.config.offerCompany,
      $start_date: user?.data.config.offerStartAt,
    });
  };

  if (onboardingInformationQuery.isLoading)
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner size="lg" />
      </div>
    );

  const offerCountryCode = user?.data.config.offerCountryCode;
  const onboardingInformation = onboardingInformationQuery.data?.data;

  const defaultValues: Partial<TMxPersonalInformationSchema> = {
    ...onboardingInformation?.address,
    ...onboardingInformation?.payee,
    ...onboardingInformation?.personalIds,
  };

  return (
    <Form<TMxPersonalInformationSchema, typeof mxPersonalInformationSchema>
      onSubmit={async (payee) => {
        handleOnSubmit(payee);
      }}
      schema={mxPersonalInformationSchema}
      className="space-y-8 divide-y divide-gray-200"
      options={{ defaultValues, shouldUnregister: true }}
    >
      {({ formState, register, resetField, watch }) => {
        const nationality = watch('nationality');
        const isNational = nationality === offerCountryCode;

        return (
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Personal Information
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Please fill in your personal details as they appear on your government issued ID.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-2">
                  <InputField
                    label="First name"
                    error={formState.errors['firstName']}
                    registration={register('firstName')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="Middle name (optional)"
                    error={formState.errors['middleName']}
                    registration={register('middleName')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="Last name"
                    error={formState.errors['lastName']}
                    registration={register('lastName')}
                    helperText="Exactly as it appears on your government issued ID"
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="Phone number"
                    error={formState.errors['phoneNumber']}
                    registration={register('phoneNumber')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="Birth date"
                    type="date"
                    error={formState.errors['birthdate']}
                    registration={register('birthdate')}
                  />
                </div>
                <div className="sm:col-span-4">
                  <BetaCountrySelect
                    label="Nationality"
                    error={formState.errors['nationality']}
                    registration={register('nationality')}
                  />
                </div>
              </div>
            </div>
            <div className="pt-8">
              <div>
                <p className="mt-1 text-sm text-gray-500">
                  Please fill in your personal ID information.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-2">
                  <InputField
                    label="Tax number"
                    helperText="RFC"
                    error={formState.errors['taxId']}
                    registration={register('taxId')}
                    className="uppercase"
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="Social security number"
                    helperText="IMSS"
                    error={formState.errors['socialSecurityId']}
                    registration={register('socialSecurityId')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="National ID number"
                    helperText="CURP"
                    error={formState.errors['nationalId']}
                    registration={register('nationalId')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="Document ID number"
                    helperText="Passport / INE"
                    error={formState.errors['identityId']}
                    registration={register('identityId')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <SelectField
                    label="Civil Status"
                    error={formState.errors['civilStatus']}
                    registration={register('civilStatus')}
                    options={[
                      { label: '', value: '' },
                      { label: 'Single', value: 'single' },
                      { label: 'Married', value: 'married' },
                      { label: 'Divorced', value: 'divorced' },
                      { label: 'Divorce in process', value: 'in-divorce' },
                      { label: 'Widowed', value: 'widowed' },
                      { label: 'Cohabitation', value: 'cohabitation' },
                    ]}
                  />
                </div>
                <div className="sm:col-span-2">
                  <SelectField
                    label="Do you currently hold a housing loan?"
                    error={formState.errors['hasInfonavitLoan']}
                    registration={register('hasInfonavitLoan')}
                    helperText="INFONAVIT"
                    options={[
                      { label: 'No', value: 'no' },
                      { label: 'Yes', value: 'yes' },
                    ]}
                  />
                </div>
                {!isNational && (
                  <div className="sm:col-span-2">
                    <InputField
                      label="Work permit number"
                      error={formState.errors['workPermitId']}
                      registration={register('workPermitId')}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="pt-8">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">Dependants</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Please fill in your dependants information.
                </p>
              </div>
              <Dependants />
            </div>
            <div className="pt-8">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">Address</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Please enter the address you will be working from.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <InputField
                    label="Street"
                    error={formState.errors['street']}
                    registration={register('street')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="City"
                    error={formState.errors['city']}
                    registration={register('city')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <State countryCode={watch('countryCode')} />
                </div>
                <div className="sm:col-span-1">
                  <InputField
                    label="Postal code"
                    error={formState.errors['postalCode']}
                    registration={register('postalCode')}
                  />
                </div>
                <div className="sm:col-span-3">
                  <BetaCountrySelect
                    label="Country"
                    error={formState.errors['countryCode']}
                    registration={register('countryCode', {
                      onChange: () => {
                        resetField('state', { defaultValue: '' });
                      },
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-end">
                <Button
                  isLoading={createMutation.isLoading}
                  disabled={createMutation.isLoading}
                  type="submit"
                  size="lg"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        );
      }}
    </Form>
  );
};
