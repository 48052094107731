import { useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { Button, Spinner } from '@/components/Elements';
import { Form, InputField, SelectField } from '@/components/Form';
import { ColombiaBankSelect } from '@/features/countries';
import { BetaCountrySelect } from '@/features/misc';
import { useAuth } from '@/lib/auth';
import { mixpanelTrackSubmit } from '@/lib/mixpanel';

import { BankDetailsDTO, useBankDetails } from '../../api/bankDetails';
import { useInformation } from '../../api/getInformation';
import { State } from '../State';

const detailsSchema = z.object({
  name: z.string().trim().min(1, 'Required'),
  account: z.string().trim().min(1, 'Required'),
  type: z.string().min(1, 'Required'),
});

const addressSchema = z.object({
  street: z.string().trim().min(1, 'Required'),
  city: z.string().trim().min(1, 'Required'),
  state: z.string().trim().min(1, 'Required'),
  postalCode: z.string().trim().min(1, 'Required'),
  bankCountryCode: z.string().trim().min(1, 'Required'),
});

const coBankingSchema = z.object({
  ...detailsSchema.shape,
  ...addressSchema.shape,
});

export type TCoBankingSchema = z.infer<typeof coBankingSchema>;

const bankDetailsDTO = (values: TCoBankingSchema): BankDetailsDTO => {
  return {
    bankDetails: {
      name: values.name,
      account: values.account,
      type: values.type,
      countryCode: 'CO',
      currencyCode: 'COP',
    },
    bankAddress: {
      street: values.street,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      countryCode: values.bankCountryCode,
    },
  };
};

export const CoBankingForm = () => {
  const navigate = useNavigate();

  const onboardingInformationQuery = useInformation();
  const bankDetailsMutation = useBankDetails();
  const { user } = useAuth();

  const handleOnSubmit = async (values: TCoBankingSchema) => {
    await bankDetailsMutation.mutateAsync(bankDetailsDTO(values), {
      onSettled: (data) => {
        if (data) navigate('/onboarding/documentation');
      },
    });
    mixpanelTrackSubmit('submit_bank_details', {
      $worker_type: user?.data.config.offerType,
      $company: user?.data.config.offerCompany,
      $start_date: user?.data.config.offerStartAt,
    });
  };

  if (onboardingInformationQuery.isLoading)
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner size="lg" />
      </div>
    );

  const onboardingInformation = onboardingInformationQuery.data?.data;

  const defaultValues: Partial<TCoBankingSchema> = {
    ...onboardingInformation?.bankAddress,
    ...onboardingInformation?.bankDetails,
    bankCountryCode: onboardingInformation?.bankAddress.countryCode,
  };

  return (
    <div>
      <Form<TCoBankingSchema, typeof coBankingSchema>
        onSubmit={async (payee) => {
          handleOnSubmit(payee);
        }}
        schema={coBankingSchema}
        className="space-y-8 divide-y divide-gray-200"
        options={{ defaultValues, shouldUnregister: true }}
      >
        {({ formState, register, resetField, watch }) => (
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Bank Account Details
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Please enter the account where you’d like to receive your payments.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-2">
                  <ColombiaBankSelect>
                    <SelectField
                      label="Financial institution"
                      error={formState.errors['name']}
                      registration={register('name')}
                      options={[{ label: '', value: '' }]}
                    />
                  </ColombiaBankSelect>
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="Transit and account number"
                    error={formState.errors['account']}
                    registration={register('account')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <SelectField
                    label="Account type"
                    error={formState.errors['type']}
                    registration={register('type')}
                    options={[
                      { label: '', value: '' },
                      { label: 'Current', value: 'current' },
                      { label: 'Savings', value: 'savings' },
                    ]}
                  />
                </div>
              </div>
            </div>

            <div className="pt-8">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Bank Account Address
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Please enter the address tied to your bank account.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <InputField
                    label="Street"
                    error={formState.errors['street']}
                    registration={register('street')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="City"
                    error={formState.errors['city']}
                    registration={register('city')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <State countryCode={watch('bankCountryCode')} />
                </div>
                <div className="sm:col-span-1">
                  <InputField
                    label="Postal code"
                    error={formState.errors['postalCode']}
                    registration={register('postalCode')}
                  />
                </div>
                <div className="sm:col-span-3">
                  <BetaCountrySelect
                    label="Country"
                    error={formState.errors['bankCountryCode']}
                    registration={register('bankCountryCode', {
                      onChange: () => {
                        resetField('state', { defaultValue: '' });
                      },
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-end">
                <Button
                  isLoading={bankDetailsMutation.isLoading}
                  disabled={bankDetailsMutation.isLoading}
                  type="submit"
                  size="lg"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};
