import { Well } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { PageHeading } from '@/components/Layout/PageHeading';

import { Documents } from '../components/Documents';

const title = 'Benefits';

export const Benefits = () => (
  <ContentLayout title={title}>
    <Well>
      <>
        <PageHeading title={title} />
        <Documents />
      </>
    </Well>
  </ContentLayout>
);
