import { useState } from 'react';

import { Button } from '@/components/Elements';
import { FormDrawer } from '@/components/Form';
import { useAuth } from '@/lib/auth';

import {
  CreateAdditionalCompensationsDTO,
  useCreateAdditionalCompensations,
} from '../../api/createAdditionalCompensations';
import { useAdditionalCompensations } from '../../api/getAdditionalCompensations';

import { ActionForm, CreateAdditionalCompensationSchema } from './ActionForm';
import { UploadedFiles } from './UploadedFiles';
import { UploadSingleFileForm } from './UploadSingleFile';

const maxInputs = 10;

export const CreateAdditionalCompensations = () => {
  const { user } = useAuth();

  const [files, setFiles] = useState<CreateAdditionalCompensationsDTO['files']>([]);

  const additionalCompensationsQuery = useAdditionalCompensations();
  const createAdditionalCompensationsMutation = useCreateAdditionalCompensations();

  if (user?.data.status === 'terminated') return null;

  const deleteFiles = () => setFiles([]);

  const handleDrawerClose = () => {
    deleteFiles();
  };

  const handleOnSubmit = async (values: CreateAdditionalCompensationSchema) => {
    await createAdditionalCompensationsMutation.mutateAsync(
      { ...values, files },
      {
        onSuccess: () => {
          deleteFiles();
        },
      }
    );
  };

  if (!additionalCompensationsQuery.data) return null;

  const pendingFile = files.filter((file) => file.path === '').length > 0;

  return (
    <FormDrawer
      isDone={createAdditionalCompensationsMutation.isSuccess}
      triggerButton={<Button className="w-full lg:w-auto">Request</Button>}
      title="Request additional compensation"
      submitButton={
        <Button
          form="create-additional-compensation"
          type="submit"
          isLoading={createAdditionalCompensationsMutation.isLoading}
          disabled={createAdditionalCompensationsMutation.isLoading || pendingFile}
        >
          Submit
        </Button>
      }
      onDrawerClose={handleDrawerClose}
    >
      <div className="flex flex-col gap-6">
        <ActionForm onSubmit={handleOnSubmit} />
        <div>
          <label htmlFor="file" className="text-sm font-medium text-gray-700">
            Attach receipts (optional)
          </label>
          <UploadSingleFileForm
            disabled={files.length >= maxInputs || createAdditionalCompensationsMutation.isLoading}
            formId="upload-single-file"
            onSuccess={({ data }) => {
              setFiles([...files, data]);
            }}
            onMutate={() => {
              const filePlaceholder = { name: 'Uploading...', path: '' };
              setFiles([...files, filePlaceholder]);
            }}
          />
          <UploadedFiles
            isDisabled={createAdditionalCompensationsMutation.isLoading}
            files={files}
            setFiles={setFiles}
          />
        </div>
      </div>
    </FormDrawer>
  );
};
