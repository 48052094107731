import { cloneElement } from 'react';

import { SelectFieldProps } from '@/components/Form';

import { useTypes } from '../api/getTypes';

export const TypeSelect = ({ children }: { children: React.ReactElement }) => {
  const typesQuery = useTypes({
    config: {
      staleTime: Infinity,
    },
  });

  if (typesQuery.isLoading) return cloneElement<SelectFieldProps>(children, { disabled: true });

  if (!typesQuery.data) return children;

  const { data: types } = typesQuery.data;

  const typeOptions = types.map((type) => ({
    label: type,
    value: type,
  }));

  return cloneElement<SelectFieldProps>(children, {
    options: [{ label: '', value: '' }, ...typeOptions],
  });
};
