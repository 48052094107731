import { Pagination, Pill, Spinner, Table } from '@/components/Elements';
import { EmptyState } from '@/components/Elements/EmptyState';
import { SectionHeading } from '@/components/Layout/SectionHeading';
import { StatusColor } from '@/types';
import { replaceHyphensWithSpaces } from '@/utils/format';

import { useTimeOff } from '../api/getTimeOff';
import { Data, TStatusTypes } from '../types';

import { CancelTimeOff } from './CancelTimeOff';

const Status = ({ status, id, requesterType }: Pick<Data, 'status' | 'id' | 'requesterType'>) => {
  const canCancel = status === 'submitted' || status === 'accepted';
  const isWorker = requesterType === 'worker';

  if (canCancel && isWorker) return <CancelTimeOff id={id} />;

  const statusTypes: TStatusTypes = {
    submitted: StatusColor.Gray,
    accepted: StatusColor.Green,
    rejected: StatusColor.Red,
    canceled: StatusColor.Red,
  };

  return <Pill color={statusTypes[status as keyof TStatusTypes]}>{status}</Pill>;
};

export const TimeOffTable = ({ addEntryButton }: { addEntryButton: JSX.Element | null }) => {
  const timeOffQuery = useTimeOff();

  if (timeOffQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!timeOffQuery.data) return null;

  const { data, links, meta } = timeOffQuery.data;

  if (meta.total < 1)
    return (
      <div className="bg-ebony-clay-50 p-12 sm:border sm:border-ebony-clay-100 sm:bg-white sm:p-24">
        <EmptyState title="No time-off requests found" triggerButton={addEntryButton} />
      </div>
    );

  return (
    <div>
      <SectionHeading actionComponents={[addEntryButton]} />
      <Table<Data>
        data={data}
        isFetching={timeOffQuery.isFetching && timeOffQuery.isPreviousData}
        columns={[
          {
            title: 'Type',
            field: 'requestType',
            Cell: ({ entry: { requestType } }) => (
              <p className="capitalize-first">{replaceHyphensWithSpaces(requestType)}</p>
            ),
          },
          {
            title: 'Request Date',
            field: 'requestDate',
          },
          {
            title: 'Description',
            field: 'description',
            isFullWidth: true,
            Cell: ({ entry: { description } }) => (
              <p className="max-w-md truncate">{description}</p>
            ),
          },
          {
            title: 'Start Date',
            field: 'startDate',
            isFullWidth: true,
          },
          {
            title: 'End Date',
            field: 'endDate',
          },
          {
            title: 'Status',
            field: 'status',
            Cell: ({ entry: { status, id, requesterType } }) => (
              <Status status={status} id={id} requesterType={requesterType} />
            ),
          },
        ]}
        pagination={
          <Pagination
            links={links}
            meta={meta}
            path="/time-off"
            isFetching={timeOffQuery.isFetching && timeOffQuery.isPreviousData}
          />
        }
      />
    </div>
  );
};
