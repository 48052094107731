import { useQuery } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Data } from '@/types';

type TTimeOffBalance = {
  type: string | null;
  period: string;
  daysTaken?: number;
  vacationDays?: number;
};

export const getTimeOffBalance = (): Promise<Data<TTimeOffBalance>> =>
  axios.get(`${API_BASE_PATH}/time-off/balance`);

type QueryFnType = typeof getTimeOffBalance;

type UseTimeOffOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useTimeOffBalance = ({ config }: UseTimeOffOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['time-off-balance'],
    queryFn: () => getTimeOffBalance(),
  });
};
