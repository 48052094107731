import { Button, NavLink, Pill, Well } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { useAuth } from '@/lib/auth';
import { StatusColor } from '@/types';
import { formatDate } from '@/utils/format';
import { windowReplaceOriginUrl } from '@/utils/url';

export const Dashboard = () => {
  const { user } = useAuth();

  if (!user) {
    windowReplaceOriginUrl();
    return null;
  }

  return (
    <ContentLayout title="Dashboard" showTitle={false}>
      <div className="flow-root">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
            <div className="grid grid-cols-1 gap-4 lg:col-span-2">
              <Well>
                <div className="sm:flex sm:space-x-5">
                  <div className="flex-shrink-0">
                    <img
                      className="mx-auto h-20 w-20 rounded-full"
                      src={user?.data.avatar}
                      alt="user avatar"
                    />
                  </div>
                  <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                    <p className="text-sm font-medium text-ebony-clay-500">Welcome back,</p>
                    <p className="text-xl font-bold text-ebony-clay-900 sm:text-2xl">
                      {user?.data.fullName}
                    </p>
                    <div className="sm:flex sm:justify-between">
                      <p className="text-sm font-medium text-ebony-clay-500">
                        {user?.data.jobTitle}
                      </p>
                      {user?.data.status === 'terminated' && (
                        <Pill color={StatusColor.Red}>Terminated</Pill>
                      )}
                    </div>
                  </div>
                </div>
              </Well>
            </div>

            <div className="grid grid-cols-1 gap-4">
              <Well>
                <>
                  <h2
                    id="latest-payment-info-title"
                    className="bg-ebony-clay-50 p-3 pb-6 text-base font-medium text-ebony-clay-900 sm:bg-white sm:p-0"
                  >
                    Most recent payment
                  </h2>
                  <div className="mt-6">
                    <ul className="-my-5 divide-y divide-ebony-clay-200">
                      <li className="py-5">
                        {user?.data.lastPaystub ? (
                          <div>
                            <h3 className="text-sm font-semibold text-ebony-clay-900">
                              You got paid on {formatDate(user?.data.lastPaystub)}
                            </h3>
                            <div className="mt-3">
                              <NavLink to="/worker/payments" tabIndex={-1}>
                                <Button className="w-full sm:w-auto">See payments</Button>
                              </NavLink>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <h3 className="text-sm font-semibold text-ebony-clay-900">
                              You don&apos;t have any payments
                            </h3>
                          </div>
                        )}
                      </li>
                    </ul>
                  </div>
                </>
              </Well>

              <Well>
                <>
                  <h2
                    id="recent-hires-title"
                    className="bg-ebony-clay-50 p-3 pb-6 text-base font-medium text-ebony-clay-900 sm:bg-white sm:p-0"
                  >
                    Upcoming Payment
                  </h2>
                  <div className="mt-6 flow-root">
                    <ul className="-my-5 divide-y divide-ebony-clay-200">
                      <li className="py-4">
                        <div className="flex items-center space-x-4">
                          <div className="min-w-0 flex-1">
                            <h3 className="text-sm font-semibold text-ebony-clay-900">
                              Your next payment is on {formatDate(user?.data.upcomingPayday)}
                            </h3>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </>
              </Well>
            </div>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
