import { pluralizeWord } from '@/utils/format';

import { useTimeOffBalance } from '../api/getTimeOffBalance';

export const TimeOffStats = () => {
  const timeOffBalanceQuery = useTimeOffBalance();

  if (timeOffBalanceQuery.isLoading) {
    return <div className="h-3 w-3/4 animate-pulse rounded bg-ebony-clay-100" />;
  }

  if (!timeOffBalanceQuery.data) return null;

  const timeOffBalance = timeOffBalanceQuery.data.data;

  const totalDays =
    typeof timeOffBalance.vacationDays === 'number'
      ? `${String(timeOffBalance.vacationDays)} day${pluralizeWord(timeOffBalance.vacationDays)}`
      : '';

  const usedDays =
    typeof timeOffBalance.daysTaken === 'number'
      ? `${String(timeOffBalance.daysTaken)} day${pluralizeWord(timeOffBalance.daysTaken)}`
      : '';

  return (
    <div className="flex gap-8 text-sm text-ebony-clay-500">
      {timeOffBalance.type && (
        <p>
          Policy:{' '}
          <span className="font-semibold capitalize text-ebony-clay-900">
            {timeOffBalance.type}
          </span>
        </p>
      )}
      <p>
        Period: <span className="font-semibold text-ebony-clay-900">{timeOffBalance.period}</span>
      </p>
      <p>
        Total: <span className="font-semibold text-ebony-clay-900">{totalDays}</span>
      </p>
      <p>
        Used: <span className="font-semibold text-ebony-clay-900">{usedDays}</span>
      </p>
    </div>
  );
};
