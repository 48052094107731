import { PropsWithChildren } from 'react';

import { Head } from '../Head';

type ContentLayoutProps = {
  title: string;
  showTitle?: boolean;
};

export const ContentLayout = ({ children, title }: PropsWithChildren<ContentLayoutProps>) => (
  <>
    <Head title={title} />
    <main className="flex-1 py-6 sm:py-12">
      <div className="mx-auto max-w-7xl sm:px-6">
        <div className="space-y-6">{children}</div>
      </div>
    </main>
  </>
);
